import React, { useState, useRef, useCallback, useEffect } from 'react';

import { ReactComponent as LeftArrow } from '../../../../assets/num-in-left-arrow.svg';

import {
  PickerWrapper,
  SelectedValue,
  PickerDropDown,
  HorizontalLine,
  NumInputWrapper,
  ArrowWrapper,
  NumTextInput,
  ApplyButton,
  NumInputButtonWrapper
} from './quantity-input.style';


function QuantityInput({ name, value, type, onChange =_=>null, placeholder, activeWidth }) {
  const [listVisibility, setListVisibility] = useState(false);
  const wrapperRef = useRef();

  const hideList = useCallback(() => setListVisibility(false), [setListVisibility]);
  const toggleVisibility = useCallback(() => setListVisibility(v => !v), [setListVisibility]);

  const handleClick = useCallback(evt => {
    if (!wrapperRef.current) return;
    
    if (!wrapperRef.current.contains(evt.target)) {
      hideList();
    }
  }, [hideList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick]);

  return (
    <PickerWrapper ref={wrapperRef}>
      <SelectedValue active={listVisibility} {...{ value, type, placeholder, activeWidth, toggleVisibility }} />

      <PickerDropDown isVisible={listVisibility}>
        <HorizontalLine />
        <NumberInput name={name} onChange={onChange} hideList={hideList} />
      </PickerDropDown>
    </PickerWrapper>
  );
}

function NumberInput({ name, onChange, hideList }) {
  const [count, setCount] = useState(1);

  function onCountChange({ target: { value } }) {
    if (value !== '' && (isNaN(Number(value)) || value < 1 || value > 9999)) {
      return;
    }

    setCount(value);
  }

  function onAdd() {
    setCount(c => c+1);
  }

  function onSub() {
    if (count > 1) {
      setCount(count -1);
    }
  }

  function onApply() {
    onChange({ target: { name, value: count } });
    hideList();
  }

  return (
    <NumInputButtonWrapper>
      <NumInputWrapper>
        <ArrowWrapper onClick={onSub}><LeftArrow /></ArrowWrapper>
        <NumTextInput value={count} onChange={onCountChange} />
        <ArrowWrapper onClick={onAdd} style={{ transform: 'rotate(180deg)' }}><LeftArrow /></ArrowWrapper>
      </NumInputWrapper>
      <ApplyButton value='OK' onClick={onApply} />
    </NumInputButtonWrapper>
  );
}

export default QuantityInput;
