import styled from 'styled-components';


export const ListItem = styled.button.attrs({
  type: 'button'
})`
  display: block;
  margin: 0;
  padding: 10px 28px;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 1em;
`;

export const ItemText = styled.p`
  display: inline;
  margin: 0;
  margin-left: -5px;
  font-size: 1.2em;
  color: #0B3656;
`;

export const ItemHtmlDiv = styled.div`
  margin: 0;
  font-size: 1.2em;
  color: #0B3656;
  margin-left: -5px;
`;

export const InfoText = styled.p`
  display: inline;
  color: #979EAA;
  font-weight: normal;
  margin: 0;
  font-size: 1.2em;
`;

export const ListingWrapper = styled.div`
  background-color: #FFFFFF;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(11, 54, 86, 0.2) 0 3px 15px;
  & ${ListItem}:nth-child(even) {
    background-color: #F7F9FA;
  }
`;
