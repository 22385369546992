import styled from 'styled-components';

export const PageWrapper = styled.div`
  position: relative;
  padding: 40px 0;
  font-size: 14px;
  @media (max-width: 1300px) {
    font-size: 12px;
  }
`;

export const GoBack = styled.p`
  font-family: Lato;
  margin: 0;
  margin-left: 10vw;
  font-size: 1.4em;
  font-weight: bold;
  color: #0084ff;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 10vw 1fr 1em 1fr 1em 1fr 10vw;
  grid-template-rows: 35vw 35vw 35vw;
  grid-template-areas:
    '.  monday  .  tuesday   .      wednesday   .   '
    '.  thursday  .  friday   .      saturday   .   '
    '.  sunday  .  card  card   card  card'
    '.  .  .  card  card   card  card';

  grid-row-gap: 2.7em;
  margin-top: 3vw;

  @media (max-width: 1600px) {
    grid-template-rows: 41vw 41vw 41vw;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
`;

export const FormWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 1.2%;
  transform: translateX(-50%);
`;
