import React from 'react';
import styled, { keyframes } from 'styled-components';

function Loader() {
  return <LoaderWrapper>
    <span className="loader-box"></span>
    <span className="loader-box"></span>
    <span className="loader-box"></span>
  </LoaderWrapper>
};

const fadeOutIn = keyframes`
  0%   { background-color : rgba(0,146,255,1); }
  100% { background-color : rgba(0,146,255,0); }
`;

const LoaderWrapper = styled.div`
  height : 8px;
  width  : 36px; /* (6 * <margin: 2px>) + (3 * <width: 8px>) */;
  margin: 30px auto;
  .loader-box {
    display                   : inline-block;
    height                    : 8px;
    width                     : 8px;
    margin                    : 0px 2px;
    background-color          : rgb(0,146,255);
    animation-name            : ${fadeOutIn};
    animation-duration        : 500ms;
    animation-iteration-count : infinite;
    animation-direction       : alternate;
  }

  .loader-box:nth-child(1) { animation-delay: 250ms; } /* (1/2) * <animation-duration: 500ms */
  .loader-box:nth-child(2) { animation-delay: 500ms; } /* (2/2) * <animation-duration: 500ms */
  .loader-box:nth-child(3) { animation-delay: 750ms; } /* (3/2) * <animation-duration: 500ms */
`;

export default Loader;