import React from 'react';

import { Wrapper, Square, CheckMark } from './checkbox.style';


const Checkbox = ({ name, value, onChange = () => null }) => (
  <Wrapper onClick={() => onChange({ target: { name, value: !value }})}>
    <Square>{ value && <CheckMark /> }</Square>
  </Wrapper>
);

export default Checkbox;
