import React from "react";
import styled from "styled-components";

import FilledButton from "../../../components/buttons/filled-button";
import UnfilledButton from "../../../components/buttons/unfilled-button";

export const Form = styled.form`
  min-width: 45em;
  position: relative;
  padding: 3em 7em;
  box-sizing: border-box;
  border-radius: 10px;
  border: 3px solid #0084ff;
  background-color: #ffffff;
`;

export const FormField = ({ renderIcon = () => null, children, style }) => (
  <FormFieldWrapper style={style}>
    <IconWrapper>{renderIcon()}</IconWrapper>
    {children}
  </FormFieldWrapper>
);

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.4em 0;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  width: 3.2em;
  margin-right: 1.8em;
`;

export const FormFieldText = styled.p`
  font-family: "Lato";
  margin: 0;
  font-size: 1.2em;
  color: ${(p) => (p.disabled ? "#CCCCCC" : "#0084FF")};
`;

export const FormFieldTextInput = styled.input.attrs({
  type: "text",
})`
  font-family: "Lato";
  margin: 0;
  font-size: 1.2em;
  color: #0084ff;
  border: none;
  outline: none;
  width: 80%;
  &::placeholder {
    color: #979eaa;
    font-style: italic;
  }
`;

export const BottomButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2.6em auto 0 auto;
`;

export const CancelButton = styled(UnfilledButton).attrs({
  value: "ANNULLA",
})`
  width: 10.3em;
  height: 3.25em;
  border-radius: 10px;
  font-size: 1.1em;
  margin-right: 11px;
  &:hover {
    border-color: #0079ea;
    color: #0079ea;
    background-color: #fafafa;
  }
`;

export const DeleteButton = styled(UnfilledButton).attrs({
  value: "ELIMINA",
})`
  width: 10.3em;
  height: 3.25em;
  border-radius: 10px;
  font-size: 1.1em;
  margin-right: 11px;
  &:hover {
    border-color: #ff3958;
    color: #ff3958;
  }
`;

export const SaveButton = styled(FilledButton).attrs({
  type: "submit",
  value: "SALVA",
})`
  width: 10.3em;
  height: 3.25em;
  border-radius: 10px;
  font-size: 1.1em;
  &:hover {
    border-color: #0079ea;
    background-color: #0079ea;
  }
`;

export const ConfirmDeleteButton = styled(UnfilledButton).attrs({
  value: "ELIMINA",
})`
  width: 10.3em;
  height: 3.25em;
  border-radius: 10px;
  font-size: 1.1em;
  margin-right: 11px;
  &:hover {
    border-color: #ff3958;
    color: #ff3958;
  }
`;

export const CancelDeleteButton = styled(FilledButton).attrs({
  type: "submit",
  value: "ANNULLA",
})`
  width: 10.3em;
  height: 3.25em;
  border-radius: 10px;
  font-size: 1.1em;
  &:hover {
    border-color: #0079ea;
    background-color: #0079ea;
  }
`;

export const CloseButton = ({ onClose }) => (
  <CloseButtonClickable onClick={onClose}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.208"
      height="25.208"
      viewBox="0 0 25.208 25.208"
    >
      <g transform="translate(12.604 -10.482) rotate(45)">
        <line
          y2="32.649"
          transform="translate(16.324 0)"
          fill="#0084FF"
          stroke="#0084FF"
          strokeWidth="3"
        />
        <line
          y2="32.649"
          transform="translate(32.649 16.324) rotate(90)"
          fill="#0084FF"
          stroke="#0084FF"
          strokeWidth="3"
        />
      </g>
    </svg>
  </CloseButtonClickable>
);

const CloseButtonClickable = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 2em;
  right: 1.9em;
  transform: scale(0.8);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Square = styled.div`
  width: ${(p) => p.dim};
  height: ${(p) => p.dim};
  background-color: #0084ff;
`;

export const ColorItem = styled.div`
  height: ${(p) => (p.dim ? p.dim : 30)}px;
  width: ${(p) => (p.dim ? p.dim : 30)}px;
  border: ${(p) =>
    p.color === "#FFFFFF" || p.color === "white" ? "solid 1px black" : "none"};
  box-sizing: border-box;
  background-color: ${(p) => p.color};
`;
