import styled from 'styled-components';

import { ReactComponent as BarCodeIcon } from '../../../../assets/barcode.svg';

export const AutocompleteWrapper = styled.div`
  margin: 0 0.85em 30px 0.85em;
`;

export const BarCodeButton = styled(BarCodeIcon)`
  cursor: pointer;
`;
