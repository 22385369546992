import React from "react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";

import { ScrollThumbWrapper, ScrollThumb } from "./app-scrollable.style";

const AppScrollable = styled(Scrollbars).attrs(() => ({
  renderThumbVertical: () => (
    <ScrollThumbWrapper>
      <ScrollThumb />
    </ScrollThumbWrapper>
  ),
}))``;

export default AppScrollable;
