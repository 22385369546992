import styled from 'styled-components';


export const OuterWrapper = styled.div`
  position: relative;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
`;
