import axios from 'axios';
import moment from 'moment';
import { therapyApiURL } from './utils.service';

// setup default request setting
axios.defaults.baseURL = therapyApiURL;
axios.defaults.headers.common.Accept = 'application/json';

class Api {
  static token = undefined;

  static request = async (method, url, params, headers, data, useToken) => {
    if (useToken) {
      const token = Api.token;
      headers.Authorization = `Bearer ${token}`;
    }
    return axios({ method, url, params, headers, data });
  };

  static get = async (url, params = {}, useToken = false) =>
    this.request('get', url, params, {}, {}, useToken);
  static post = async (url, data = {}, useToken = false) =>
    this.request('post', url, {}, {}, data, useToken);
  static delete = async (url, data = {}, useToken = false) =>
    this.request('delete', url, {}, {}, data, useToken);
  static patch = async (url, data = {}, useToken = false) =>
    this.request('patch', url, {}, {}, data, useToken);
  static put = async (url, data = {}, useToken = false) =>
    this.request('put', url, {}, {}, data, useToken);

  // GET requests
  static getPatients = async (data) => this.get('/patients', data, true);
  static searchPatients = async (data) =>
    this.get('/patients/search', { data }, true);
  static getPatient = async (id) => this.get('/patients/' + id, {}, true);
  static getTherapy = async (patientId, therapyId) =>
    this.get(
      `/patients/${patientId}/therapies/${therapyId}?currentDate=${encodeURIComponent(
        moment().format().toString()
      )}`,
      {},
      true
    );
  static medicineSearch = async (name) =>
    this.get('/medicines/search', { q: name }, true);
  static getMedicineById = async (id) =>
    this.get('/medicines/check', { id }, true);
  static getMedicineDetails = async (id, code) =>
    this.get(`/medicines/foglietto?id=${id}&code=${code}`, {}, true);
  // POST requests
  static setPatient = async (data) => this.post('/patients', data, true);
  static editTherapy = async (
    patientId,
    therapyId,
    therapies,
    dateRange = {}
  ) => {
    return this.post(
      `/patients/${patientId}/therapies`,
      {
        id: therapyId,
        therapies,
        startDate: moment(dateRange.startDate, 'DD/MM/YYYY')
          .format('DD/MM/YYYY')
          .toString(),
        endDate: moment(dateRange.endDate, 'DD/MM/YYYY')
          .format('DD/MM/YYYY')
          .toString(),
      },
      true
    );
  };
  static login = async (username, password) =>
    this.post(`/login?email=${username}&password=${password}`, {
      username,
      password,
    });

  static getUser = async () => this.get(`/me`, {}, true);
  // DELETE requests
  static deleteTherapy = async (p_id, t_id) =>
    this.delete(`/patients/${p_id}/therapies/${t_id}`, {}, true);
  static deletePatient = async (p_id) =>
    this.delete(`/patients/${p_id} `, {}, true);
  // PATCH requests
  // PUT requests
  // UTILS
  static on401 = (callback) => {
    return axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (response && response.status === 401) callback(response);
        return Promise.reject(error);
      }
    );
  };
  static removeInterceptor = (interceptor) =>
    axios.interceptors.request.eject(interceptor);
}

export default Api;
