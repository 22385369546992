import React, { Fragment } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import PharmacyLogo from '../assets/pdf-test-image.png';
import isoWeek from 'dayjs/plugin/isoWeek';
import moment from 'moment';
import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { useZustandStore } from './zustand-store';

dayjs.locale('it');
dayjs.extend(isoWeek);

// Create styles
const styles = StyleSheet.create({
  page: {
    marginHorizontal: 25,
    marginTop: 30,
    marginBottom: 5,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  infoColumn: {
    width: '100px',
    marginRight: '40px',
    marginBottom: '15px',
  },
  text: {
    fontSize: 11,
    fontFamily: 'Helvetica',
  },
  image: {
    marginVertical: 16,
    marginHorizontal: 16,
    height: 32,
    width: 32,
  },
  headerBig: {
    fontSize: 15,
  },
  header: {
    fontSize: 11,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
  },
  headerBold: {
    fontSize: 11,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    width: '10px',
    height: '10px',
    border: '1px solid black',
    marginHorizontal: '5px',
  },
  signatureBox: {
    borderBottom: '1px solid black',
    height: '30px',
    fontSize: '11',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    top: 0,
    left: 500,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

// Create Document Component
const TherapyPDF = (data) => {
  // react-pdf/renderer seems to only accept props if inserted into an array for some reason, they turn into empty objects otherwise...

  const { user } = useZustandStore();

  const [therapy_code, therapyArray, patient, therapyCalendar, createdAt] =
    data.data;
  const therapyFlatMap = therapyArray.flatMap((pill) => pill);

  // Gets all pills names with no duplicate, useful for mapping each pill in the PDF list
  const allPillsNames = _.uniqBy(therapyFlatMap, 'name').map(
    (pill) => pill.name
  );

  const pillsOccurancesInCalendar = therapyCalendar.reduce((acc, current) => {
    if (
      therapyArray[current.isoWeekday() - 1].length &&
      therapyArray[current.isoWeekday() - 1].find((medicine) =>
        allPillsNames.find((pillName) => medicine !== pillName)
      )
    ) {
      return [
        ...acc,
        {
          pills: therapyArray[current.isoWeekday() - 1],
          day: current,
        },
      ];
    }

    return acc;
  }, []);

  const final = allPillsNames.reduce(
    (accNames, currentName) => ({
      ...accNames,
      [currentName]: pillsOccurancesInCalendar.reduce(
        (medicineOccurrance, currentOccurrance) => {
          const pills = currentOccurrance.pills.reduce(
            (pillsInOccurrance, pill) => {
              if (pill.name === currentName) {
                return [
                  ...pillsInOccurrance,
                  {
                    quantity: pill.quantity,
                    time: pill.time,
                    day: currentOccurrance.day,
                    note: pill.note,
                  },
                ];
              }
              return pillsInOccurrance;
            },
            []
          );
          return [...medicineOccurrance, ...pills];
        },
        []
      ),
    }),
    {}
  );

  return (
    <Document>
      <Page
        size='A4'
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          marginVertical: 15,
          marginHorizontal: 25,
        }}
      >
        <Text
          style={{
            marginLeft: '-25px',
            justifySelf: 'center',
            alignSelf: 'center',
            display: 'inline',
            fontSize: '11px',
            fontFamily: 'Helvetica-Bold',
          }}
        >
          Foglio di Lavorazione
        </Text>
        <View style={{ minHeight: '150px' }}>
          <View
            style={{
              textAlign: 'right',
              width: '50%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '20px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <Image
                style={{
                  width: '46',
                  height: '46',
                  marginRight: '20px',
                }}
                src={PharmacyLogo}
              />
              <View>
                <Text style={styles.headerBig}>
                  {user.pharmacy.name && user.pharmacy.name}
                </Text>
                <Text style={{ fontSize: '14px' }}>
                  {user.name && user.name}
                </Text>{' '}
              </View>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '30px',
            }}
          >
            <View style={styles.infoColumn}>
              <Text style={styles.header}>Codice terapia</Text>
              <Text style={styles.headerBold}>{therapy_code}</Text>
            </View>
            <View style={styles.infoColumn}>
              <Text style={styles.header}>Data preparazione</Text>
              <Text style={styles.headerBold}>
                {dayjs(createdAt).format('DD/MM/YYYY')}
              </Text>
            </View>
            <View style={styles.infoColumn}>
              <Text style={styles.header}>Medico curante</Text>
              <Text style={styles.headerBold}>{patient.doctor}</Text>
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View style={styles.infoColumn}>
              <Text style={styles.header}>Nome paziente</Text>
              <Text style={styles.headerBold}>{patient.name}</Text>
            </View>
            <View style={styles.infoColumn}>
              <Text style={styles.header}>Età</Text>
              <Text style={[styles.headerBold, { fontWeight: 'bold' }]}>
                {moment().diff(
                  moment(patient.birth_date, 'DD/MM/YYYY'),
                  'years'
                )}
              </Text>
            </View>
            {patient.cf && (
              <View style={(styles.infoColumn, { marginRight: '0px' })}>
                <Text style={styles.header}>Codice Fiscale</Text>
                <Text style={styles.headerBold}>{patient.cf}</Text>
              </View>
            )}
          </View>
        </View>

        <View style={styles.content}>
          {Object.entries(final).map(([pillName, pills], index) => (
            <Fragment key={pillName}>
              <Text
                style={[
                  styles.text,
                  {
                    marginBottom: '8px',
                    marginTop: '16px',
                    fontFamily: 'Helvetica-Bold',
                  },
                ]}
              >{`${index + 1}. ${pillName}`}</Text>

              {pills.map((pill, pillIndex) => (
                <View
                  key={pillIndex}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '150px',
                      marginRight: '50px',
                    }}
                  >
                    <Text style={[styles.text, { fontWeight: 900 }]}>
                      {dayjs(pill.day).format('DD MMM')}
                    </Text>
                    <Text style={[styles.text, { fontWeight: 900 }]}>-</Text>
                    <Text
                      style={[
                        styles.text,
                        { fontSize: '10px', marginVertical: '2px' },
                      ]}
                    >
                      ore {''}
                      {pill.time}
                    </Text>
                    <Text style={[styles.text, { fontSize: '8px' }]}>
                      {' '}
                      Quantità: {pill.quantity}{' '}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={[
                        styles.text,
                        { justifySelf: 'flex-start', fontSize: '8px' },
                      ]}
                    >
                      {pill.note && pill.note}
                    </Text>
                  </View>
                </View>
              ))}
            </Fragment>
          ))}
        </View>

        {/*Create artificial bottom margin, on all but last page*/}
        <View
          fixed
          style={{}}
          render={({ pageNumber, totalPages }) =>
            pageNumber !== totalPages && (
              <View fixed style={{ height: '30px' }}></View>
            )
          }
        ></View>

        {/*Quality check and signature boxes, they go after the pills list/content and at the bottom of the last page only*/}
        <View
          wrap={false}
          style={{
            justifySelf: 'flex-end',
            height: '180px',
            marginTop: '10px',
          }}
        >
          <View
            wrap={false}
            style={{
              justifySelf: 'flex-end',
              marginVertical: '3px',
              fontSize: '12px',
            }}
          >
            <Text style={[styles.text, { marginVertical: '2px' }]}>
              - Verifica della correttezza delle procedure eseguite
            </Text>
            <Text style={[styles.text, { marginVertical: '2px' }]}>
              - Controllo dell'aspetto e della tenuta delle dosi personalizzate
            </Text>
            <Text style={[styles.text, { marginVertical: '2px' }]}>
              - Verifica del numero di dosi personalizzate allestite
            </Text>
            <Text style={[styles.text, { marginVertical: '2px' }]}>
              - Verifica della correttezza delle compilazioni dell'etichetta
            </Text>
          </View>
          <View
            style={{
              border: '1px solid black',
              height: '20px',
              width: '92%',
              justifyContent: 'center',
              justifySelf: 'center',
              marginVertical: '5px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: '10px',
                fontSize: '12px',
              }}
            >
              <Text>Controlli di qualità effettuati</Text>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={[styles.checkboxGroup, { marginRight: '15px' }]}>
                  <Text>Conforme</Text>
                  <div style={styles.checkbox}></div>
                </div>

                <div style={styles.checkboxGroup}>
                  <Text>Non conforme</Text>
                  <div style={styles.checkbox}></div>
                </div>
              </div>
            </div>
          </View>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: '10px',
              marginVertical: '10px',
              width: '92%',
              fontSize: '12px',
            }}
          >
            <div style={styles.checkboxGroup}>
              <Text>Effettuata pulizia piano lavoro</Text>
              <div style={styles.checkbox}></div>
            </div>

            <div style={styles.checkboxGroup}>
              <Text>Accettazione preparazione</Text>
              <div style={styles.checkbox}></div>
            </div>

            <div style={styles.checkboxGroup}>
              <Text>Pulizia attrezzatura</Text>
              <div style={styles.checkbox}></div>
            </div>
          </div>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '92%',
              justifySelf: 'center',
              marginTop: '10px',
            }}
          >
            <div
              style={[styles.signatureBox, { flex: '1', marginRight: '20px' }]}
            >
              <Text>Data</Text>
            </div>
            <div
              style={[
                styles.signatureBox,
                { flex: '1.5', marginRight: '20px' },
              ]}
            >
              <Text>Farmacista responsabile</Text>
            </div>

            <div style={[styles.signatureBox, { flex: '1.5' }]}>
              <Text>Farmacista preparatore</Text>
            </div>
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default TherapyPDF;
