import React, { useState, useEffect } from 'react';
import useForm from './useform';

import Checkbox from '../../../components/checkbox/checkbox';
import SearchBar from './search-bar/search-bar';
import Picker from './picker/picker';
import QuantityInput from './quantity-input/quantity-input';
import Modal from 'react-modal';
import {
  modalStyles,
  medicineModalStyles,
} from '../../../services/utils.service';
import { BarCodeButton } from '../medicine-form/search-bar/search-bar.style';
import { ReactComponent as BarCodeImage } from '../../../assets/barcode-image.svg';

import { ReactComponent as MedicineCountIcon } from '../../../assets/medicine-count-icon.svg';
import { ReactComponent as MedicineDeltatimeIcon } from '../../../assets/medicine-deltatime-icon.svg';
import { ReactComponent as MedicineTimeIcon } from '../../../assets/medicine-time-icon.svg';
import { ReactComponent as MedicineColorIcon } from '../../../assets/medicine-color-icon.svg';
import { ReactComponent as MedicineNoteIcon } from '../../../assets/medicine-note-icon.svg';
import { ReactComponent as MedicineExpirationIcon } from '../../../assets/medicine-expiration-icon.svg';
import { ReactComponent as MedicineTypeIcon } from '../../../assets/medicine-type-icon.svg';

import {
  Form,
  FormField,
  FormFieldText,
  FormFieldTextInput,
  CloseButton,
  BottomButtonsWrapper,
  DeleteButton,
  CancelButton,
  SaveButton,
  Square,
} from './medicine-form.style';
import { uppercaseFirstLetter } from '../../../services/utils.service';

import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const INITIAL_VALUE = {
  name: '',
  type: '',
  hasLactoseGluten: false,
  quantity: '',
  method: undefined,
  startDate: new Date(),
  deltatime: undefined,
  hour: '00',
  minute: '00',
  colorText: '',
  expirationDate: '',
  note: '',
};

function MedicineForm({
  initialValue = INITIAL_VALUE,
  closeForm,
  onSubmit: submit,
  onDelete,
  isEditing,
}) {
  const { form, handleChange } = useForm(initialValue, {});
  const [trackingBarCode, setTrackingBarCode] = useState(form.trackingCode);
  const [trackingBarCodeModal, setTrackingBarCodeModal] = useState(false);
  const [medicineId, setMedicineId] = useState(form.id);

  // Tracking barcode useEffect
  useEffect(() => {
    const updateTrackingCode = (event) => {
      if (event.keyCode === 13) {
        return setTrackingBarCodeModal(false);
      }
      setTrackingBarCode((prev) => (!prev ? event.key : `${prev}${event.key}`));
    };

    if (trackingBarCodeModal) {
      document.addEventListener('keypress', updateTrackingCode);
    } else {
      document.removeEventListener('keypress', updateTrackingCode);
    }

    return () => document.removeEventListener('keypress', updateTrackingCode);
  }, [trackingBarCode, trackingBarCodeModal]);

  async function onSubmit(evt) {
    evt.preventDefault();
    if (form.name === '') {
      toast.warning('Inserire il nome del farmaco', {});
    } else if (form.quantity === undefined) {
      toast.warning('Inserire la quantità o il dosaggio', {});
    } else if (form.method === undefined) {
      toast.warning('Inserire la modalità di somministrazione', {});
    } else if (form.deltatime === undefined) {
      toast.warning('Inserire la fascia oraria', {});
    } else if (form.colorText === '') {
      toast.warning('Inserire un colore', {});
    } else {
      const newForm = {
        ...form,
        id: medicineId,
        time: `${form.hour}:${form.minute}`,
        trackingCode: trackingBarCode,
      };
      await submit(newForm);
      closeForm();
    }
  }
  const handleLactoseGluten = (flag) =>
    handleChange({ target: { name: 'hasLactoseGluten', value: flag } });

  const handleMedicineId = (id) => setMedicineId(id);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <CloseButton onClose={closeForm} />

        <SearchBar
          name='name'
          value={form.name}
          onChange={(data) => handleChange(data)}
          setLactoseGluten={handleLactoseGluten}
          setMedicineId={handleMedicineId}
          placeholder='Nome farmaco'
          trackingCode
        />

        <Modal
          ariaHideApp={false}
          isOpen={trackingBarCodeModal}
          style={modalStyles}
          shouldCloseOnOverlayClick
          onRequestClose={() => setTrackingBarCodeModal(false)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '40px 60px',
            }}
          >
            <CloseButton onClose={() => setTrackingBarCodeModal(false)} />
            <BarCodeImage />
            <div
              style={{
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: '20px',
                lineHeight: '36px',
                color: '#0084FF',
                paddingTop: '70px',
                textAlign: 'center',
              }}
            >
              <p style={{ fontSize: '25px' }}>Numero di identificazione</p>
              <p style={{ paddingTop: '5px' }}>In attesa di scansione...</p>
            </div>
          </div>
        </Modal>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '30px',
            marginLeft: '-2px',
          }}
        >
          <BarCodeButton
            onClick={() => {
              setTrackingBarCode();
              setTrackingBarCodeModal(true);
            }}
            style={{ height: '45.5px', width: '49px', marginRight: '25px' }}
          />
          <input type='hidden' name='id' value={medicineId}></input>
          <FormFieldTextInput
            placeholder='Numero di identificazione (opzionale)'
            autoComplete='cc-csc'
            name='trackingCode'
            pattern='^\d{9,10}$'
            value={trackingBarCode}
            title='Codice di 9-10 cifre.'
            onChange={(e) => setTrackingBarCode(e.target.value)}
          />
        </div>

        {form.hasLactoseGluten && (
          <FormField
            renderIcon={() => (
              <Checkbox name='hasLactoseGluten' value={form.hasLactoseGluten} />
            )}
          >
            <FormFieldText disabled>
              Presenza di lattosio e/o glutine
            </FormFieldText>
          </FormField>
        )}

        <FormField renderIcon={() => <MedicineTypeIcon width='20.25' />}>
          <FormFieldTextInput
            placeholder='Tipo di farmaco (opzionale)'
            autoComplete='off'
            name='type'
            value={uppercaseFirstLetter(form.type)}
            onChange={handleChange}
          />
        </FormField>

        <FormField renderIcon={() => <Square dim='1.3em' />}>
          <Picker
            placeholder='Modalità di somministrazione'
            name='method'
            value={form.method}
            onChange={handleChange}
            list={[
              'A stomaco pieno',
              'A stomaco vuoto',
              '30 minuti prima del pasto',
              'Durante il pasto',
              'Prima di andare a letto',
            ]}
            maxHeight={300}
            activeWidth='21em'
          />
        </FormField>

        <FormField renderIcon={() => <MedicineCountIcon width='18' />}>
          <QuantityInput
            placeholder='Quantità'
            name='quantity'
            value={form.quantity}
            onChange={handleChange}
            activeWidth='18em'
          />
        </FormField>

        <FormField renderIcon={() => <MedicineDeltatimeIcon width='20.25' />}>
          <Picker
            placeholder='Seleziona fascia oraria'
            name='deltatime'
            value={form.deltatime}
            onChange={handleChange}
            list={['Colazione', 'Pranzo', 'Cena', 'Sera']}
            infoList={[
              '(6:00 - 11:00)',
              '(11:15 - 18:00)',
              '(18:15 - 21:30)',
              '(21:45 - 24:00)',
            ]}
            maxHeight={250}
            activeWidth='19em'
          />
        </FormField>

        <FormField renderIcon={() => <MedicineTimeIcon width='21.75' />}>
          <div
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Picker
              name='hour'
              value={form.hour}
              onChange={handleChange}
              list={Array.from(Array(24).keys()).map((e) =>
                ('0' + e).slice(-2)
              )}
              maxHeight={250}
              activeWidth='5em'
            />

            <FormFieldText
              style={{
                margin: '-8px 18px 0 18px',
                color: '#979EAA',
                fontSize: '1.8em',
              }}
            >
              :
            </FormFieldText>

            <Picker
              name='minute'
              value={form.minute}
              onChange={handleChange}
              list={Array.from(Array(60).keys()).map((e) =>
                ('0' + e).slice(-2)
              )}
              maxHeight={250}
              activeWidth='5em'
            />
          </div>
        </FormField>

        <FormField renderIcon={() => <MedicineColorIcon width='20.25' />}>
          <FormFieldTextInput
            placeholder='Colore'
            name='colorText'
            autoComplete='off'
            value={uppercaseFirstLetter(form.colorText)}
            onChange={handleChange}
          />
        </FormField>

        <FormField renderIcon={() => <MedicineExpirationIcon width='20.25' />}>
          <FormFieldTextInput
            placeholder='Data di scadenza medicinale (opzionale)'
            autoComplete='off'
            name='expirationDate'
            maxLength='10'
            pattern='^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$'
            title='GG/MM/AAAA'
            value={form.expirationDate}
            onChange={handleChange}
          />
        </FormField>

        <FormField renderIcon={() => <MedicineNoteIcon width='20.25' />}>
          <FormFieldTextInput
            placeholder='Raccomandazioni particolari (opzionale)'
            name='note'
            autoComplete='off'
            value={uppercaseFirstLetter(form.note)}
            onChange={handleChange}
          />
        </FormField>

        <BottomButtonsWrapper>
          {isEditing && <DeleteButton onClick={onDelete} />}
          <CancelButton onClick={closeForm} />
          <SaveButton />
        </BottomButtonsWrapper>
      </Form>
    </>
  );
}

export default MedicineForm;
