import React, { useState, useEffect, useRef, useCallback } from 'react';

import AppListing from '../../../../components/app-listing/app-listing';
import DropDownMenu from '../../../../components/dropdown-menu/dropdown-menu.component';

import {
  ListWrapper,
  SelectedValue,
  HorizontalLine
} from './picker.style';


function Picker({ name, value, placeholder, onChange = _ => null, list, infoList, maxHeight, activeWidth, textStyle={} }) {
  const [listVisibility, setListVisibility] = useState(false);
  const wrapperRef = useRef();

  const toggleVisibility = useCallback(() => setListVisibility(v => !v), [setListVisibility]);
  const hideList = useCallback(() => setListVisibility(false), [setListVisibility]);

  function onItemClick(item) {
    hideList();
    onChange({ target: { name, value: item }});
  }

  const handleClick = useCallback(evt => {
    if (!wrapperRef.current) return;
    
    if (!wrapperRef.current.contains(evt.target)) {
      hideList();
    }
  }, [hideList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick]);
  
  return (
    <ListWrapper ref={wrapperRef}>
      <SelectedValue active={listVisibility} {...{ value, placeholder, activeWidth, toggleVisibility, textStyle }} />

      <DropDownMenu isVisible={listVisibility}>
        <HorizontalLine />
        <AppListing {...{ onItemClick, maxHeight, list, infoList }} />
      </DropDownMenu>
    </ListWrapper>
  );
}

export default Picker;
