import React from 'react';

function Footer() {
  return (
    <div
    style={{
        marginTop: 'auto',
        height: 150,
        width: '100%',
        backgroundColor: '#0B3656'
    }}
    />
  );
}

export default Footer;