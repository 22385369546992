import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Api from './../../services/api.service.js';
import PatientCard from './../../components/patient_card/patientCard.component.js';
import moment from 'moment';
import { PatientWrapper, ModalElement, DeleteModal } from './patients.style.js';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Patients() {
  const [showPatient, setShowPatient] = useState(false);
  const [patientShowed, setPatientShowed] = useState({
    birth_date: '',
    name: '',
    pathology: '',
    hospital: '',
    department: '',
    doctor: '',
    pharmacist: '',
  });
  const [patients, setPatients] = useState([]);
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const customStyles = {
    content: {
      padding: 0,
      borderRadius: 5,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const getPatients = async () => {
    const {
      data: { data: response, last_page: lastPage },
    } = await Api.getPatients();
    let patientsVect = response;
    for (let i = 2; i <= lastPage; i++) {
      const {
        data: { data: response },
      } = await Api.getPatients({ page: i });
      patientsVect = [...patientsVect, ...response];
    }
    setPatients(patientsVect);
  };

  useEffect(() => {
    getPatients();
  }, []);

  const setData = (label, value) => {
    const newData = Object.assign({}, patientShowed);
    newData[label] = value;
    setPatientShowed(newData);
  };

  async function deletePatient(id) {
    await Api.deletePatient(id);
    window.location.reload();
  }

  const sendChanges = async (data) => {
    const valideDate = moment(data.birth_date, 'DD/MM/YYYY', true).isValid();
    if (
      data.name !== '' &&
      valideDate !== false &&
      data.pathology !== '' &&
      data.hospital !== '' &&
      data.doctor !== '' &&
      data.pharmacist !== ''
    ) {
      setSaved(true);
      await Api.setPatient(data);
      setTimeout(() => {
        getPatients();
        window.location.reload();
      }, 3000);
    } else {
      toast.warning('Dati non validi', {});
    }
  };

  return (
    <>
      <PatientWrapper>
        {patients.map((data, index) => (
          <PatientCard
            key={index}
            setPatient={(data) => setPatientShowed(data)}
            setShow={(value) => setShowPatient(value)}
            data={data}
          />
        ))}
        <Modal
          isOpen={showPatient}
          onRequestClose={() => setShowPatient(false)}
          style={customStyles}
          ariaHideApp={false}
        >
          <ModalElement
            setData={(label, value) => setData(label, value)}
            closeModal={() => setShowPatient(false)}
            openDeleteModal={() => setDeleted(true)}
            sendChanges={(data) => sendChanges(data)}
            data={patientShowed}
          />
        </Modal>
        <Modal isOpen={deleted} style={customStyles} ariaHideApp={false}>
          <DeleteModal
            deletePatient={() => deletePatient(patientShowed.id)}
            closeModal={() => setDeleted(false)}
          />
        </Modal>
        <Modal isOpen={saved} style={customStyles} ariaHideApp={false}>
          <div style={{ margin: 30, textAlign: 'center' }}>
            <p>Dati paziente aggiornati correttamente.</p>
            <p>Attendere prego...</p>
          </div>
        </Modal>
      </PatientWrapper>
    </>
  );
}

export default Patients;
