import React, { useState } from 'react';
import Modal from 'react-modal';
import Datalist from './../datalist/datalist.component.js';
import Api from './../../services/api.service.js';
import {
  HeaderWrapper,
  HeaderLogo,
  AddButton,
  LogoutButton,
  FlexRowBox,
} from './header.style.js';
import { ModalElement, Text } from './../../screens/patients/patients.style.js';
import moment from 'moment';
import { CloseButton } from '../../screens/therapy/medicine-form/medicine-form.style.js';
import { PrintButton } from '../../screens/therapy/patient-card/patient-card.style.js';
import { useHistory } from 'react-router-dom';
import { useZustandStore } from '../../services/zustand-store';

import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const width = window.innerWidth;

function Header({ loggedIn, loading }) {
  const history = useHistory();

  const { user, token, setUser, setToken } = useZustandStore();

  const [showPatient, setShowPatient] = useState(false);
  const [saved, setSaved] = useState(false);
  const [patientShowed, setPatientShowed] = useState({
    birth_date: '',
    name: '',
    pathology: '',
    hospital: '',
    department: '',
    doctor: '',
    pharmacist: '',
  });
  const customStyles = {
    content: {
      padding: 0,
      borderRadius: 5,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  // const [startDate, setStartDate] = useState(new Date());

  const setData = (label, value) => {
    const newData = Object.assign({}, patientShowed);
    newData[label] = value;
    setPatientShowed(newData);
  };

  const addPatient = async () => {
    const valideDate = moment(
      patientShowed.birth_date,
      'DD/MM/YYYY',
      true
    ).isValid();
    if (
      patientShowed.name !== '' &&
      valideDate !== false &&
      patientShowed.pathology !== '' &&
      patientShowed.hospital !== '' &&
      patientShowed.doctor !== '' &&
      patientShowed.pharmacist !== ''
    ) {
      setSaved(true);
      await Api.setPatient(patientShowed);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.warning('Dati non validi', {});
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Modal
          isOpen={showPatient}
          onRequestClose={() => setShowPatient(false)}
          style={customStyles}
          ariaHideApp={false}
        >
          <ModalElement
            newPatient={true}
            setData={(label, value) => setData(label, value)}
            closeModal={() => setShowPatient(false)}
            sendChanges={() => addPatient()}
            data={patientShowed}
          />
        </Modal>

        <FlexRowBox style={{ gap: 45 }}>
          <HeaderLogo />
          {token && user ? (
            <FlexRowBox
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: 10,
                gap: '30px',
              }}
            >
              <div>
                <Text
                  style={{
                    color: '#979EAA',
                    fontSize: width > 1280 ? 18 : 15,
                    fontFamily: 'Lato',
                  }}
                >
                  Nome
                </Text>
                <Text
                  style={{
                    color: '#0084FF',
                    fontSize: width > 1280 ? 18 : 15,
                    fontFamily: 'Lato',
                    marginBottom: 10,
                  }}
                >
                  {user.name}
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    color: '#979EAA',
                    fontSize: width > 1280 ? 18 : 15,
                    fontFamily: 'Lato',
                  }}
                >
                  Farmacia
                </Text>
                <Text
                  style={{
                    color: '#0084FF',
                    fontSize: width > 1280 ? 18 : 15,
                    fontFamily: 'Lato',
                    marginBottom: 10,
                  }}
                >
                  {user.pharmacy.name}
                </Text>
              </div>
            </FlexRowBox>
          ) : null}
        </FlexRowBox>
        <FlexRowBox style={{ alignItems: 'center' }}>
          {token && <Datalist width={width > 1280 ? 320 : 200} data={[]} />}
          {token && <AddButton onClick={() => setShowPatient(true)} />}
          {token && (
            <LogoutButton
              onClick={async () => {
                setUser({ name: '...', pharmacy: { name: '...' } });
                setToken('');
                Api.token = null;
                history.push('/login');
              }}
            />
          )}
        </FlexRowBox>
        <Modal isOpen={saved} style={customStyles} ariaHideApp={false}>
          <div style={{ display: 'flex', margin: 30 }}>
            <CloseButton
              onClose={() => {
                history.goBack();
              }}
            ></CloseButton>
            <p>Paziente inserito correttamente</p>
            <PrintButton
              style={{ justifySelf: 'flex-end' }}
              onClose={() => {
                history.goBack();
              }}
            >
              Continua
            </PrintButton>
          </div>
        </Modal>
      </HeaderWrapper>
    </>
  );
}

export default Header;
