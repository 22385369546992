import styled from 'styled-components';


export const ScrollThumbWrapper = styled.div`
  position: absolute;
  width: 5px;
  right: 6px;
`;

export const ScrollThumb = styled.div`
  position: absolute;
  top: 10%;
  height: 80%;
  width: 100%;
  border-radius: 20px;
  background-color: #0084FF;
`;
