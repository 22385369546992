import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FlexRowBox = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 3px;
`;

export const DatalistWrapper = styled.div`
    margin-right: 45px;
`;

export const DatalistInput = styled.input`
    ::placeholder,
    ::-webkit-input-placeholder{
    font-weight: normal
    }
    border: none;
    padding: 0%;
    width: 100%;
    background-color: #F7F9FA;
    font-family: Lato;
    font-style:italic;
    ${p => p.width === 320 ? "font-size: 20px;" : ""}
    outline: none;
    align-self: flex-end;
    color: #0084FF;
    font-weight: bold;
`;

export const DatalistResults = styled.div`
    z-index: 100;
    position: absolute;
    width: 280px;
    margin-left: 15px;
    -webkit-box-shadow: -1px 11px 23px -8px rgba(0,0,0,0.6);
    -moz-box-shadow: -1px 11px 23px -8px rgba(0,0,0,0.6);
    box-shadow: -1px 11px 23px -8px rgba(0,0,0,0.6);    
`;

export const ResultRow = ({ item, index, search }) => {
    const id = "/" + item.id;
    return (
        <ResultRowWrapper even={index % 2 === 0}>
            <Link
                to={'/patients' + id}
                onClick={() => search("")}
                style={{
                    textDecoration: 'none',
                    fontStyle: 'Lato'
                }}>
                <FlexRowBox style={{ paddingLeft: 38, alignItems: 'center', height: '100%' }}>
                    <ResultText isName={true}>{item.name}</ResultText>
                    <ResultText> - {item.birth_date}</ResultText>
                </FlexRowBox>
            </Link>
        </ResultRowWrapper>
    );
}

export const ResultRowWrapper = styled.div`
    height: 35px;
    justify-content: center;
    align-items: center;
    background-color: ${p => p.even ? 'white' : '#F7F9FA'};
`;

export const ResultText = styled.p`
    color: ${p => p.isName ? 'black' : '#979EAA'};
`;
