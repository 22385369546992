import React, { useState } from "react";
import styled from "styled-components";
import Api from "./../../services/api.service.js";
import { useZustandStore } from "./../../services/zustand-store";

function Login() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, setToken } = useZustandStore();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alingItems: "center",
        justifyContent: "center",
      }}
    >
      <LoginWrapper>
        <TextHeader>LOGIN</TextHeader>
        <InputForm>
          <TextInfo>Nome</TextInfo>
          <InputName
            value={name}
            onChange={(event) => setName(event.target.value)}
            placeholder="Inserisci e-mail"
            style={{
              fontSize: 20,
              color: "#0084FF",
            }}
            type="text"
          />
          <TextInfo>Password</TextInfo>
          <InputPassword
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Inserisci password"
            style={{
              fontSize: 20,
              color: "#0084FF",
            }}
            type="password"
          />
          <TextInfo></TextInfo>
          <Password
            value="Password dimenticata?"
            style={{
              fontSize: 12,
              color: "#0084FF",
              cursor: "pointer",
            }}
            type="button"
          />
        </InputForm>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ButtonLogin
            value="LOGIN"
            style={{
              fontSize: 25,
              color: "white",
              cursor: "pointer",
            }}
            type="button"
            onClick={async () => {
              try {
                const {
                  data: { token: response },
                } = await Api.login(name, password);
                setToken(response);
                Api.token = response;

                try {
                  const { data } = await Api.getUser();
                  setUser(data[0]);
                } catch (e) {
                  console.log(`Get User Error: ${e}`);
                }

                //window.location.assign(window.location.origin + '/patients');
              } catch (e) {
                console.log("Login button error", e);
              }
            }}
          />
        </div>
      </LoginWrapper>
    </div>
  );
}

export default Login;

export const LoginWrapper = styled.div`
  margin: 80px auto 80px;
  border: 2px solid #0084ff;
  display: inline-block;
  border-radius: 10px;
  padding: 45px 70px 45px 70px;
`;
export const TextHeader = styled.p`
  font-size: 30px;
  margin: 0px;
`;
export const InputForm = styled.div`
  margin-top: 45px;
`;
export const ButtonLogin = styled.input`
  background-color: #0084ff;
  width: 180px;
  height: 65px;
  border-radius: 10px;
  margin: 93px auto 0 auto;
`;
export const TextInfo = styled.p`
  font-size: 15px;
  margin: 0;
  color: #979eaa;
`;
export const InputName = styled.input`
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #979eaa;
  }
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
  border-bottom-color: #c9d3e2;
  width: 360px;
  margin-bottom: 35px;
`;
export const InputPassword = styled.input`
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #979eaa;
  }
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
  width: 360px;
  border-bottom-color: #c9d3e2;
`;
export const Password = styled.input`
  background-color: white;
  border: none;
  margin: 10px 0px 0px 0px;
`;
