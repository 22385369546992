import create from "zustand";
import { persist } from "zustand/middleware";

export const useZustandStore = create()(
  persist(
    (set) => ({
      user: { name: "...", pharmacy: { name: "..." } },
      token: "",
      setUser: (user) => set({ user }),
      setToken: (token) => set({ token }),
    }),
    {
      name: "terapia-facile-webapp-storage",
    }
  )
);
