import React, { useState } from 'react';
import AppScrollable from '../../../components/app-scrollable/app-scrollable';
import Collapsible from 'react-collapsible';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  Box,
  Text,
  BoxHeader,
  DayText,
  AddButton,
  MedicineRow,
  EditButton,
  MedicineName,
  MedicineTrackingCode,
  MedicineInfo,
  MedicineNote,
  HasGlutenIcon,
  MedicineDetailsButton,
} from './therapy-details.style';
import {
  lowercaseFirstLetter,
  alternatingColor,
} from '../../../services/utils.service';
import Api from '../../../services/api.service';
import Modal from 'react-modal';
import { detailsModalStyles } from '../../../services/utils.service';
import { CloseButton } from '../medicine-form/medicine-form.style';

function buildInfo({
  deltatime,
  time,
  method,
  color,
  hasLactoseGluten,
  colorText,
}) {
  return (
    [
      `${deltatime} - ${time}`,
      method,
      `colore ${lowercaseFirstLetter(colorText)}`,
    ].join(' | ') + (hasLactoseGluten ? ' | ' : '')
  );
}

function TherapyDayDetails({ therapy_code, day, medicines, openForm }) {
  const [detailsData, setDetailsData] = useState({});
  const [detailsModal, setDetailsModal] = useState();

  let dayIt;
  switch (day) {
    case 'monday':
      dayIt = 'Lunedì';
      break;
    case 'tuesday':
      dayIt = 'Martedì';
      break;
    case 'wednesday':
      dayIt = 'Mercoledì';
      break;
    case 'thursday':
      dayIt = 'Giovedì';
      break;
    case 'friday':
      dayIt = 'Venerdì';
      break;
    case 'saturday':
      dayIt = 'Sabato';
      break;
    case 'sunday':
      dayIt = 'Domenica';
      break;
    default:
      throw new Error('Unknown day in onSubmit(TherapyScreen)');
  }

  if (medicines.length === 0) {
    return (
      <Box
        style={{
          gridArea: day,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DayText style={{ marginBottom: 11 }}>{dayIt}</DayText>
        <AddButton onClick={() => openForm(day)} />
      </Box>
    );
  }

  return (
    <Box style={{ gridArea: day }}>
      {detailsData && (
        <Modal
          ariaHideApp={false}
          isOpen={detailsModal}
          style={detailsModalStyles}
          shouldCloseOnOverlayClick
          onRequestClose={() => {
            setDetailsModal(false);
            setDetailsData({});
          }}
        >
          <CloseButton
            onClose={() => {
              setDetailsModal(false);
              setDetailsData({});
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '85%',
              marginBottom: 30,
            }}
          >
            <MedicineName>{detailsData.denominazione}</MedicineName>
            <Text
              style={{
                fontSize: 20,
              }}
            >
              Codice prodotto: {detailsData.codiceProdotto}
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              overflow: 'auto',
            }}
          >
            <div>
              <Collapsible
                open={true}
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Principi attivi
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.principiAttivi,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Eccipienti
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.eccipienti,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Indicazioni terapeutiche
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.indicazioniTerapeutiche,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Controindicazioni / Effetti indesiderati
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.controindicazioniEdEffettiIndesiderati,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Posologia
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.posologia,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Conservazione
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.conservazione,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Avvertenze
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.avvertenze,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Interazioni
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.interazioni,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Effetti indesiderati
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.effettiIndesiderati,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Sovradosaggio
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.sovradosaggio,
                  }}
                />
              </Collapsible>
              <Collapsible
                transitionTime={200}
                overflowWhenOpen='scroll'
                trigger={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '98%',
                    }}
                  >
                    <Text
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      Gravidanza ed Allattamento
                    </Text>
                    <Text
                      className='arrow'
                      style={{
                        color: '#0084FF',
                        fontSize: 22,
                        fontFamily: 'Lato',
                      }}
                    >
                      {'>'}
                    </Text>
                  </div>
                }
              >
                <Text
                  style={{
                    color: '#979EAD',
                    fontSize: 18,
                    fontFamily: 'Lato',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: detailsData.gravidanzaEdAllattamento,
                  }}
                />
              </Collapsible>
            </div>
          </div>
        </Modal>
      )}

      <BoxHeader>
        <DayText>{dayIt}</DayText>
        <AddButton onClick={() => openForm(day)} />
      </BoxHeader>
      <AppScrollable>
        {medicines.map((medicine, index) => (
          <div
            key={medicine.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: `${
                alternatingColor[index % alternatingColor.length]
              }`,
            }}
          >
            <div style={{ alignSelf: 'flex-start' }}>
              <svg
                style={{
                  padding: '35px 0px 5px 15px',

                  backgroundColor: 'inherit',
                }}
                height='32'
                width='32'
                viewBox='0 0 200 200'
              >
                <path
                  fill={medicine.color}
                  d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                />
              </svg>{' '}
              <MedicineDetailsButton
                onClick={async () => {
                  try {
                    const { data: item } = await Api.getMedicineDetails(
                      medicine.id,
                      therapy_code
                    );
                    setDetailsData(item);
                    setDetailsModal(true);
                  } catch {
                    toast.warning(
                      'Scheda tecnica non disponibile per questo farmaco',
                      {}
                    );
                  }
                }}
              ></MedicineDetailsButton>
            </div>

            <MedicineRow key={medicine.id}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div />
                <EditButton
                  onClick={() => {
                    openForm(day, medicine, index, true);
                  }}
                />
              </div>
              <MedicineName
                dangerouslySetInnerHTML={{ __html: medicine.name }}
              ></MedicineName>
              <MedicineTrackingCode>
                {medicine.trackingCode &&
                  `Nº identificativo: ${medicine.trackingCode}`}
              </MedicineTrackingCode>
              <MedicineInfo>{buildInfo(medicine)}</MedicineInfo>
              {medicine.hasLactoseGluten && <HasGlutenIcon />}
              {medicine.note && <MedicineNote>{medicine.note}</MedicineNote>}
            </MedicineRow>
          </div>
        ))}
        <br />
      </AppScrollable>
    </Box>
  );
}

export default TherapyDayDetails;
