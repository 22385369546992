import styled from "styled-components";

const FilledButton = styled.input.attrs(
  ({ fg = "#FFFFFF", bg = "#0084FF", bgh="#EEEEEE" }) => ({
    type: "button",
    fg,
    bg,
    bgh,
  })
)`
  font-family: "Fira Sans";
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 1px;
  background-color: ${(p) => p.bg};
  border: none;
  cursor: pointer;
  color: ${(p) => p.fg};
  &:hover {
    background-color: ${(p) => p.bgh};
  }
`;

export default FilledButton;
