import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Header from './components/header/header.component.js';
import Footer from './components/footer/footer.component.js';
import Login from './screens/login/login.screen.js';
import Patients from './screens/patients/patients.screen.js';
import Therapy from './screens/therapy/therapy.screen.js';
import Patient from './screens/patient/patient.screen.js';
import Api from './services/api.service.js';
import moment from 'moment';
import 'moment/locale/it';
import { useZustandStore } from './services/zustand-store';

import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Set Moment lang to Italian
moment.locale('it');

const App = () => {
  const { setUser, setToken, token } = useZustandStore();
  const [loading, setLoading] = useState(true);

  if (loading) {
    Api.token = token;
    setLoading(false);
  }

  useEffect(() => {
    Api.on401((res) => {
      setToken('');
      Api.token = undefined;
      setUser({ name: '...', pharmacy: { name: '...' } });
      toast.warning('Sessione scaduta', {});
    });
  }, [setUser, setToken]);

  return (
    <Router>
      <ToastContainer
        transition={Flip}
        position='bottom-center'
        style={{ marginBottom: '15px' }}
      />
      <div>
        <Header />
      </div>
      <div>
        {token ? (
          <Switch>
            <Route path='/patients/:p_id/therapies/:t_id' component={Therapy} />
            <Route path='/patients/:p_id/therapies' component={Therapy} />
            <Route path='/patients/:p_id/therapies' component={Therapy} />
            <Route path='/patients' exact component={Patients} />
            <Route path='/patients/:id' component={Patient} />
            <Redirect from='/' to='/patients' />
          </Switch>
        ) : (
          <Switch>
            <Route path='/login' component={Login} />
            <Redirect from='/' to='/login' />
          </Switch>
        )}
      </div>
      <Footer />
    </Router>
  );
};

export default App;
