import styled from 'styled-components';


const UnfilledButton = styled.input.attrs(({ fg='#0084FF' }) => ({ 
  type: 'button',
  fg
}))`
  font-family: 'Fira Sans';
  background-color: transparent;
  border: 3px solid ${p => p.fg};
  cursor: pointer;
  color: ${p => p.fg};
`;

export default UnfilledButton;
