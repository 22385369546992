import React, { useState } from "react";
import Api from "./../../services/api.service.js";
import {
  FlexRowBox,
  DatalistWrapper,
  DatalistInput,
  DatalistResults,
  ResultRow
} from "./datalist.style.js";
import { ReactComponent as SearchIcon } from "./../../assets/searchIcon.svg";
import { ReactComponent as RightArrow } from "./../../assets/rightArrow.svg";

function Datalist({ width }) {
  const [searchInput, setSearchInput] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [searchResults, setSearchResults] = useState([]);

  const search = async value => {
    setSearchInput(value);
    if (!isSearching) {
      setIsSearching(true);
      setRotation(90);
    }
    if (value === "") {
      setIsSearching(false);
      setRotation(0);
    } else {
      const { data: response } = await Api.searchPatients({ q: value });
      setSearchResults(response);
    }
  };

  return (
    <DatalistWrapper>
      <FlexRowBox>
        <SearchIcon height={18} style={{ alignSelf: "center" }} />
        <div>
          <FlexRowBox
            style={{
              width: width,
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "#0084FF",
              marginLeft: 15,
              alignItems: 'flex-start'
            }}
          >
            <DatalistInput
              width={width}
              type="text"
              placeholder="Cerca paziente"
              value={searchInput}
              onChange={event => search(event.target.value)}
            />
            <div
              style={{
                transform: `rotate(${rotation}deg)`,
                transition: "transform .5s"
              }}
            >
              <RightArrow height={width === 320 ? 20 : 15} />
            </div>
          </FlexRowBox>
          {isSearching && (
            <DatalistResults>
              {searchResults.map((item, index) => (
                <ResultRow key={index} {...{ item, index, search }} />
              ))}
            </DatalistResults>
          )}
        </div>
      </FlexRowBox>
    </DatalistWrapper>
  );
}

export default Datalist;
