import React from "react";
import AppScrollable from "../app-scrollable/app-scrollable";

import {
  ListingWrapper,
  ListItem,
  ItemText,
  InfoText,
  ItemHtmlDiv,
} from "./app-listing.style";

function AppListing({
  list = [],
  infoList = [],
  onItemClick = (_) => null,
  maxHeight,
  type = "string",
}) {
  const infoLen = infoList.length;

  function renderItem(item, index) {
    switch (type) {
      case "string":
        return renderString(item, index);
      case "html":
        return renderHtml(item, index);
      default:
        throw new Error("Unknown item type(AppListing)");
    }
  }

  function renderString(item, index) {
    const info =
      index < infoLen && infoList[index] ? ` ${infoList[index]}` : "";
    const style = index === 0 ? { color: "#0084FF", fontWeight: "500" } : {};

    return (
      <ListItem
        key={`${item.id} + ${index}`}
        onClick={() => onItemClick(item + info)}
      >
        <ItemText style={style}>{item}</ItemText>
        <InfoText>{info}</InfoText>
      </ListItem>
    );
  }

  function renderHtml(item, index) {
    const style = index === 0 ? { color: "#0084FF", fontWeight: "500" } : {};

    return (
      <ListItem
        key={`${item.id} ${index}`}
        onClick={() => onItemClick(item.id)}
      >
        <ItemHtmlDiv
          style={style}
          dangerouslySetInnerHTML={{ __html: item.name }}
        ></ItemHtmlDiv>
        <ItemHtmlDiv
          style={{ ...style, color: "#CCCCCC" }}
          dangerouslySetInnerHTML={{ __html: item.description }}
        ></ItemHtmlDiv>
      </ListItem>
    );
  }

  return (
    <ListingWrapper>
      <AppScrollable autoHeight autoHeightMax={maxHeight}>
        {list.map(renderItem)}
      </AppScrollable>
    </ListingWrapper>
  );
}

export default AppListing;
