import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChangeFilterIcon } from './../../assets/changeFilterIcon.svg';
import { ReactComponent as ChoiceFilterWrapper } from './../../assets/choiceFilterWrapper.svg';
import { ReactComponent as RightArrow } from './../../assets/rightArrow.svg';
import moment from 'moment';
import { Link } from 'react-router-dom';

var width = window.innerWidth;

export const FlexRowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.p`
  margin: 0;
`;

export const PatientWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 80px 120px 0px;
`;

export const PatientHeader = ({ data }) => {
  const now = moment().format('L').split('/');
  const currentDate = {
    year: now[2],
    month: now[0],
    day: now[1],
  };

  const birthDateVect = data.birth_date.split('/');
  const years = parseInt(currentDate.year) - parseInt(birthDateVect[2]);
  const months = parseInt(currentDate.month) - parseInt(birthDateVect[1]);
  const days = parseInt(currentDate.day) - parseInt(birthDateVect[0]);
  let age;
  let label = '';

  if (years > 0) {
    age = years;
    label = years > 1 ? 'anni' : 'anno';
  } else if (months > 0) {
    age = months;
    label = months > 1 ? 'mesi' : 'mese';
  } else {
    age = days;
    label = days > 1 ? 'giorni' : 'giorno';
  }
  return (
    <PatientHeaderWrapper>
      <FlexRowBox style={{ justifyContent: 'space-between' }}>
        <Text style={{ fontSize: width > 1280 ? 30 : 20 }}>
          {data.name.toUpperCase()}
        </Text>
        <Link
          to={'/patients'}
          style={{
            textDecoration: 'none',
            color: '#979EAA',
            fontWeight: 'bold',
            letterSpacing: 2,
            fontStyle: 'Fira Sans',
            fontSize: width > 1280 ? 20 : 15,
          }}
        >
          <FlexRowBox
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <RightArrow
              style={{ transform: `rotate(180deg)`, marginRight: 20 }}
            />
            TORNA AI PAZIENTI
          </FlexRowBox>
        </Link>
      </FlexRowBox>
      <FlexRowBox style={{ justifyContent: 'space-between', marginTop: 40 }}>
        <div>
          <Text
            style={{
              color: '#979EAA',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            Età
          </Text>
          <Text
            style={{
              color: '#0084FF',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
              marginBottom: 10,
            }}
          >
            {age + ' ' + label}
          </Text>
          <Text
            style={{
              color: '#979EAA',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            Patologia
          </Text>
          <Text
            style={{
              color: '#0084FF',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            {data.pathology}
          </Text>
        </div>
        <div>
          <Text
            style={{
              color: '#979EAA',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            Struttura ospedaliera
          </Text>
          <Text
            style={{
              color: '#0084FF',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
              marginBottom: 10,
            }}
          >
            {data.hospital}
          </Text>
          <Text
            style={{
              color: '#979EAA',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            Reparto
          </Text>
          <Text
            style={{
              color: '#0084FF',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            {data.department}
          </Text>
        </div>
        <div>
          <Text
            style={{
              color: '#979EAA',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            Medico curante / Medico reparto
          </Text>
          <Text
            style={{
              color: '#0084FF',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
              marginBottom: 10,
            }}
          >
            {data.doctor}
          </Text>
          <Text
            style={{
              color: '#979EAA',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            Farmacista preparatore
          </Text>
          <Text
            style={{
              color: '#0084FF',
              fontSize: width > 1280 ? 18 : 15,
              fontFamily: 'Lato',
            }}
          >
            {data.pharmacist}
          </Text>
        </div>
        <div>
          {' '}
          {data.cf && (
            <>
              <Text
                style={{
                  color: '#979EAA',
                  fontSize: width > 1280 ? 18 : 15,
                  fontFamily: 'Lato',
                }}
              >
                Codice Fiscale
              </Text>
              <Text
                style={{
                  color: '#0084FF',
                  fontSize: width > 1280 ? 18 : 15,
                  fontFamily: 'Lato',
                  marginBottom: '10px',
                }}
              >
                {data.cf}
              </Text>
            </>
          )}
        </div>
        <div></div>
      </FlexRowBox>
    </PatientHeaderWrapper>
  );
};

export const PatientHeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 120px;
  padding: 0px 30px;
  box-sizing: border-box;
`;

export const PatientBody = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const PatientBodyTitle = ({
  choosing,
  setChoosing,
  choice,
  setChoice,
  id,
}) => (
  <FlexRowBox
    style={{
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: 40,
      padding: '0px 30px',
      boxSizing: 'border-box',
    }}
  >
    <FlexRowBox style={{ alignItems: 'center' }}>
      <Text style={{ fontSize: width > 1280 ? 20 : 18, marginRight: 10 }}>
        PIANI DI CURA PRECEDENTI
      </Text>
      <button
        style={{
          paddingTop: 7,
          paddingBottom: 7,
          paddingLeft: 10,
          paddingRight: 10,
          border: 'none',
          outline: 'none',
          backgroundColor: '#0084FF',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 5,
          cursor: 'pointer',
        }}
      >
        <Link
          to={'/patients/' + id + '/therapies'}
          style={{
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold',
            letterSpacing: 2,
            fontStyle: 'Fira Sans',
            fontSize: width > 1280 ? 15 : 12,
          }}
        >
          NUOVO PIANO
        </Link>
      </button>
    </FlexRowBox>
    <div>
      <button
        onClick={() => setChoosing(!choosing)}
        style={{
          border: 'none',
          outline: 'none',
          backgroundColor: 'white',
          cursor: 'pointer',
        }}
      >
        <ChangeFilterIcon />
      </button>
      {choosing && (
        <FilterMenu {...{ setChoosing, choosing, setChoice, choice }} />
      )}
    </div>
  </FlexRowBox>
);

export const FilterMenu = ({ setChoosing, setChoice, choosing, choice }) => (
  <FilterMenuWrapper style={{ opacity: choosing ? 1 : 0, zIndex: 100 }}>
    <ChoiceFilterWrapper />
    <FilterChoiceTextWrapper>
      <FilterMenuOption
        style={{
          backgroundColor: choice === 'ancient' ? '#F7F9FA' : 'white',
        }}
        value={'TERAPIA MENO RECENTE'}
        {...{ setChoosing, setChoice, choosing }}
      />
      <FilterMenuOption
        style={{
          backgroundColor: choice === 'recent' ? '#F7F9FA' : 'white',
        }}
        value={'TERAPIA PIÙ RECENTE'}
        {...{ setChoosing, setChoice, choosing }}
      />
    </FilterChoiceTextWrapper>
  </FilterMenuWrapper>
);

export const FilterMenuWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  right: 100px;
  margin-top: -8px;
  transition: opacity 0.5s;
  box-sizing: border-box;
`;

export const FilterMenuOption = ({
  style,
  value,
  setChoosing,
  setChoice,
  choosing,
}) => (
  <FilterMenuOptionWrapper
    style={style}
    onClick={() => {
      if (value === 'TERAPIA MENO RECENTE') {
        setChoice('ancient');
      } else {
        setChoice('recent');
      }
      setChoosing(false);
    }}
    disabled={!choosing}
  >
    <Text style={{ fontSize: 20 }}>{value}</Text>
  </FilterMenuOptionWrapper>
);

export const FilterMenuOptionWrapper = styled.button`
  outline: none;
  border: none;
  background-color: white;
  height: 50px;
  cursor: pointer;
  width: 468px;
  box-sizing: border-box;
`;

export const FilterChoiceTextWrapper = styled.div`
  position: absolute;
  top: 20px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PatientTherapy = ({
  patientId,
  data,
  even,
  therapyId,
  deleteTherapy,
}) => {
  const [color, setColor] = useState('black');
  let creation = data.created_at.split(' ');
  let date = creation[0].split('-');
  date = date[2] + '/' + date[1] + '/' + date[0];
  let time = creation[1].split(':');
  time = time[0] + ':' + time[1];

  return (
    <PatientTherapyWrapper even={even}>
      <FlexRowBox
        style={{
          background: 'none',
          outline: 'none',
          cursor: 'pointer',
          width: '100%',
          border: 'none',
          textAlign: 'left',
          justifyContent: 'space-between',
        }}
      >
        <Link
          onMouseEnter={() => setColor('#0084FF')}
          onMouseLeave={() => setColor('black')}
          to={'/patients/' + patientId + '/therapies/' + data.id}
          style={{
            textDecoration: 'none',
            color: color,
            fontSize: width > 1280 ? 20 : 17,
          }}
        >
          Terapia {therapyId}, creata il {date} alle {time}
        </Link>

        <input
          style={{
            height: 30,
            borderRadius: 5,
            borderStyle: 'solid',
            borderColor: '#FF3958',
            backgroundColor: 'white',
            color: '#FF3958',
            cursor: 'pointer',
            marginRight: 5,
            outline: 'none',
          }}
          type='button'
          value='ELIMINA'
          onClick={() => deleteTherapy()}
        />
      </FlexRowBox>
    </PatientTherapyWrapper>
  );
};

export const PatientTherapyWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: ${(p) => (p.even ? '#F7F9FA' : 'white')};
  padding: 0px 30px;
`;

export const EmptyListText = styled.p`
  font-family: 'Lato';
  margin-left: 30px;
  font-size: 18px;
  color: #979eaa;
`;
