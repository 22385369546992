import React from 'react';
import styled from 'styled-components';

import Button from '../../../../components/buttons/unfilled-button';

import DropDownMenu from '../../../../components/dropdown-menu/dropdown-menu.component';
import Arrow from '../../../../components/arrow/arrow';


export const PickerWrapper = styled.div``;

export const HorizontalLine = styled.div`
  height: 3px;
  background-color: #0084FF;
`;

export const PickerDropDown = styled(DropDownMenu)`
  background-color: #FFFFFF;
  box-shadow: 0 3px 15px rgba(11, 54, 86, 0.2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const NumInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NumInputButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25em 1.56em;
`;

export const NumTextInput = styled.input.attrs({
  type: 'text'
})`
  font-family: 'Lato';
  margin: 0 0.81em;
  padding: 0.31em;
  font-size: 1.2em;
  text-align: center;
  color: #0084FF;
  border: 3px solid #0084FF;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
`;

export const ArrowWrapper = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ApplyButton = styled(Button)`
  font-family: 'Fira Sans';
  width: 75%;
  padding: 3px 0;
  margin-top: 10px;
  border-radius: 10px;
  font-size: 1.1em;
`;

export const SelectedValue = ({ active, value, type, placeholder, activeWidth, toggleVisibility }) => {
  let text;
  let style = {};

  if (value) {
    text = type ? value + '  ' + type.toLowerCase() : value;
  } else {
    text = placeholder;
    style = { color: '#979EAA', fontStyle: 'italic' };
  }

  return (
    <SelectedValueWrapper onClick={toggleVisibility} style={active ? { minWidth: activeWidth } : {}}>
      <SelectedText style={style}>{text}</SelectedText>
      <PickerArrow active={active} />
    </SelectedValueWrapper>
  );
};

const SelectedValueWrapper = styled.button.attrs(_ => ({
  type: 'button'
}))`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
`;

const SelectedText = styled.p`
  font-family: 'Lato';
  margin: 0;
  font-size: 1.2em;
  color: #0084FF;
`;

const PickerArrow = styled(Arrow)`
  margin-right: 7px;
  margin-left: 33px;
`;
