import React, { useState } from "react";

import { Wrapper, CustomInput, TextInputArrow } from "./text-input.style";

function TextInput(props) {
  const [focused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
    props.onFocus && props.onFocus();
  };

  const onBlur = () => {
    setFocused(false);
    props.onBlur && props.onBlur();
  };

  return (
    <Wrapper style={props.wrapperStyle}>
      <CustomInput {...{ ...props, onFocus, onBlur }} />
      <TextInputArrow active={focused} />
    </Wrapper>
  );
}

export default TextInput;
