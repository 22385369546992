import { useReducer } from 'react';

function useForm(initialState, validators) {
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );

  function handleChange({ target: { name, value } }) {
    if (validators[name] && !validators[name](value)) return;
    setForm({ [name]: value });
  }

  return { form, handleChange };
}

export default useForm;
