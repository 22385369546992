import React from 'react';
import {
    PatientCardWrapper,
    PatientCardTile,
    PatientCardBody
} from './patientCard.style.js';

function PatientCard({data, setShow, setPatient}) {

  return (
    <PatientCardWrapper>
        <PatientCardTile {...{data, setShow, setPatient}}>{data.name}</PatientCardTile>
        <PatientCardBody data={data}></PatientCardBody>
    </PatientCardWrapper>
  );
}

export default PatientCard;