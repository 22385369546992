import jsPDF from "jspdf";
import moment from "moment";
import { formatColor, getHTMLText } from "./utils.service";
import { LatoFont, FiraSansFont } from "./default-vfs";

class PDFRender {
  constructor(format = "l", addFonts = true) {
    const doc = new jsPDF(format);
    // add fonts to pdf file
    if (addFonts) {
      doc.addFileToVFS("FiraSansFont", FiraSansFont);
      doc.addFont("FiraSansFont", "FiraSans", "normal");
      doc.addFileToVFS("LatoFont", LatoFont);
      doc.addFont("LatoFont", "Lato", "normal");
    }

    this.doc = doc;
    this.pageWidth = doc.internal.pageSize.getWidth();
    this.pageHeight = doc.internal.pageSize.getHeight();
  }

  etichetta(num, day, patient, medicines, createdAt) {
    const doc = this.doc;
    const x = num % 2 ? this.pageWidth / 2 : 10;
    const tmp = (this.pageHeight - 50) / 6;
    let currY = 25 + tmp * Math.floor(num / 2);

    const renderPairRow = (pairList) => {
      let currX = x;
      pairList.forEach((pair) => {
        // render key
        doc.setFontType("bold");
        doc.text(currX, currY, pair.key);
        currX += doc.getTextWidth(pair.key + ` `);
        // render value
        doc.setFontType("normal");
        doc.text(currX, currY, pair.value);
        currX += doc.getTextWidth(pair.value + ` `);
      });
      currY += 3;
    };

    doc.setFontSize(7);
    renderPairRow([
      { key: "Nome paziente: ", value: patient.name },
      {
        key: "Età: ",
        value:
          "" + moment().diff(moment(patient.birth_date, "DD/MM/YYYY"), "years"),
      },
    ]);
    renderPairRow([
      { key: "Farmacista: ", value: patient.pharmacist },
      { key: "Data preparazione:", value: createdAt.format("DD/MM/YYYY") },
    ]);
    renderPairRow([
      { key: "Giorno: ", value: day },
      { key: "Fascia oraria: ", value: medicines[0].deltatime },
    ]);

    // render farmaci
    doc.setFontSize(6);
    medicines.forEach((m) => {
      renderPairRow([{ key: "Farmaco: ", value: getHTMLText(m.name) }]);

      renderPairRow([
        { key: "Quantità: ", value: m.quantity.toString() },
        { key: "Somministrazione: ", value: m.method },
        { key: "alle ore ", value: m.time },
      ]);
      renderPairRow([
        { key: "Colore: ", value: m.colorText },
        { key: "Avvisi: ", value: m.note || "Nessuno" },
      ]);
    });
  }

  patientCard(patient, x, y, rect) {
    const doc = this.doc;
    const rectY = y + 5;
    const contentX = x + 5;
    const maxWidth = rect.w - 10;
    // render title
    doc.setFont("FiraSans", "normal");
    doc.setFontSize(15);
    doc.setTextColor(11, 54, 86);
    doc.text(x + 2, y, "Scheda paziente");
    // render rect
    doc.roundedRect(x, rectY, rect.w, rect.h, 2, 2);
    // render patient data
    doc.setFontSize(15);
    let rowY = rectY + 10;
    const renderKey = (text) => {
      doc.setFontSize(9);
      doc.splitTextToSize(text, maxWidth).forEach((t) => {
        doc.text(contentX, rowY, t);
        rowY += 5;
      });
    };
    const renderValue = (text) => {
      doc.setFontSize(14);
      doc.splitTextToSize(text, maxWidth).forEach((t) => {
        doc.text(contentX, rowY, t);
        rowY += 8;
      });
    };
    renderKey("Nome e Cognome");
    renderValue(patient.name);
    renderKey("Età");
    renderValue(
      "" + moment().diff(moment(patient.birth_date, "DD/MM/YYYY"), "years")
    );
    renderKey("Patologia");
    renderValue(patient.pathology);
    renderKey("Struttura ospedaliera");
    renderValue(patient.hospital);
    renderKey("Medico curante / Medico reparto");
    renderValue(patient.doctor);
    renderKey("Farmacista preparatore");
    renderValue(patient.pharmacist);
  }

  therapyDay(title, medicines, x, y, rect) {
    const doc = this.doc;
    const rectY = y + 5;
    const contentX = x + 5;
    const maxWidth = rect.w - 10;
    // render title
    doc.setFont("FiraSans", "normal");
    doc.setFontSize(15);
    doc.setTextColor(11, 54, 86);
    doc.text(x + 2, y, title);
    // render rect
    doc.roundedRect(x, rectY, rect.w, rect.h, 2, 2);
    // render therapies
    let rowY = rectY + 10;
    medicines.forEach((m) => {
      // render medicine title
      const div = document.createElement("div");
      div.innerHTML = m.name;
      doc.setFontSize(14);
      doc.setTextColor(0, 132, 255);
      const titleTexts = doc.splitTextToSize(div.textContent.trim(), maxWidth);
      const lastRowWidth = doc.getTextWidth(titleTexts[titleTexts.length - 1]);
      titleTexts.forEach((t) => {
        doc.text(contentX, rowY, t);
        rowY += 6;
      });
      // render medicine color
      const colorDim = 4;
      doc.setFillColor(m.color);
      const colorX = contentX + lastRowWidth + 2;
      if (lastRowWidth + 2 + colorDim <= maxWidth) {
        doc.rect(colorX, rowY - 5.8 - colorDim, colorDim, colorDim, "F");
      } else {
        doc.rect(contentX, rowY - colorDim, colorDim, colorDim, "F");
        rowY += colorDim + 1;
      }
      doc.setFillColor("#FFFFFF");
      rowY += 1;
      // render medicine info
      doc.setFontSize(11);
      const infoTexts = doc.splitTextToSize(
        this.formatMedicineInfo(m),
        maxWidth
      );
      infoTexts.forEach((t) => {
        doc.text(contentX, rowY, t);
        rowY += 5;
      });
      // render medicine note
      if (m.note) {
        doc.setFont("Lato", "normal");
        doc.setTextColor(151, 158, 170);
        doc.setFontSize(9);
        const noteTexts = doc.splitTextToSize(m.note, maxWidth);
        noteTexts.forEach((t) => {
          doc.text(contentX, rowY, t);
          rowY += 4;
        });
      }

      rowY += 8;
    });
  }

  formatMedicineInfo({ deltatime, time, method, hasLactoseGluten }) {
    const data = [`${deltatime} - ${time}`, method];
    if (hasLactoseGluten) data.push("Presenza di glutine");
    return data.join(" | ");
  }
}

export default PDFRender;
