export const therapyApiURL =
  'https://app.terapiafacile.igalenici.it/api/api/v1';

export const modalStyles = {
  content: {
    padding: 0,
    border: '3px solid #0084FF',
    borderRadius: 10,
    top: '43%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const detailsModalStyles = {
  content: {
    border: '3px solid #0084FF',
    padding: 20,
    borderRadius: 10,
    top: '50%',
    left: '50%',
    right: '10%',
    bottom: '1%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '90%',
  },
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const repeatUntilConnectionDown = async (callback, ms = 500) => {
  let isMsgDisplayed = false;

  while (true) {
    try {
      return await callback();
    } catch (e) {
      if (e.message.toLowerCase() === 'network error') {
        if (!isMsgDisplayed) {
          alert('Impossibile accedere ad internet');
          isMsgDisplayed = true;
        }
      } else {
        throw e;
      }
    }

    await sleep(ms);
  }
};

export const formatColor = (color, delimiter = '/') => {
  if (typeof color === 'string') return color;
  const firstColor = color[0];
  return (
    firstColor + (typeof color[1] === 'string' ? delimiter + color[1] : '')
  );
};

export const formatMedicineInfo = ({
  deltatime,
  time,
  method,
  color,
  hasLactoseGluten,
}) =>
  [`${deltatime} - ${time}`, method, `colore ${formatColor(color)}`].join(
    ' | '
  ) + (hasLactoseGluten ? ' | Presenza di glutine' : '');

/**
 * Create an therapies array from therapies object
 * @param {Object}
 */
export const createTherapiesArrayFromObject = (therapies) => [
  therapies.monday,
  therapies.tuesday,
  therapies.wednesday,
  therapies.thursday,
  therapies.friday,
  therapies.saturday,
  therapies.sunday,
];

/**
 * Create an therapies object from therapies array
 * @param {Array}
 */
export const createTherapiesObjectFromArray = (therapies) => {
  const len = therapies.length;
  return {
    monday: len > 0 ? therapies[0] : [],
    tuesday: len > 1 ? therapies[1] : [],
    wednesday: len > 2 ? therapies[2] : [],
    thursday: len > 3 ? therapies[3] : [],
    friday: len > 4 ? therapies[4] : [],
    saturday: len > 5 ? therapies[5] : [],
    sunday: len > 6 ? therapies[6] : [],
  };
};

export const getHTMLText = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent.trim();
};

export const uppercaseFirstLetter = (str) => {
  if (str === undefined || (str && !str.length)) return str;
  if (str) return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const lowercaseFirstLetter = (str) => {
  if (str === undefined || (str && !str.length)) return str;
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
};

export const alternatingColor = ['#F7F9FA', 'white'];
