import React from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import { ReactComponent as LogoSVG } from "../../assets/logo-terapiafacile.svg";

export const FlexRowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 35px 120px;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f9fa;
  height: 50px;
`;

export const HeaderLogo = () => (
  <FlexRowBox style={{ alignItems: "center" }}>
    <Logo onClick={() => window.location.replace("/patients")}>
      <LogoSVG height="50" />
    </Logo>
    {/* <LogoName onClick={() => window.location.replace("/patients")}>TerapiaFacile</LogoName> */}
  </FlexRowBox>
);

export const HeaderLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.button`
  margin: 0;
  padding: 0;
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const LogoName = styled.p`
  margin-left: 10px;
  font-size: 25px;
  cursor: pointer;
`;

export const AddButton = ({ onClick }) => (
  <button
    {...{ onClick }}
    style={{
      padding: 0,
      border: "none",
      outline: "none",
      backgroundColor: "#0084FF",
      height: 40,
      width: 40,
      borderRadius: 20,
      marginRight: 45,
      cursor: "pointer",
    }}
  >
    <PlusIcon height="40%" width="40%" />
  </button>
);
export const LogoutButton = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    style={{
      border: "none",
      outline: "none",
      backgroundColor: "#0084FF",
      height: 40,
      width: 120,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 5,
      cursor: "pointer",
    }}
  >
    <p
      style={{
        color: "white",
        margin: 0,
        fontSize: 15,
        fontFamily: "Fira Sans",
        fontWeight: "bold",
        letterSpacing: 2,
      }}
    >
      LOGOUT
    </p>
  </button>
);
