import styled from "styled-components";
import Arrow from "../../../../../components/arrow/arrow";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1px;
  border-bottom: 2px solid #0084ff;
`;

export const CustomInput = styled.input.attrs({
  type: "text",
  autocomplete: "off",
})`
  border: none;
  outline: none;
  font-size: 1.4em;
  width: 100%;
  color: #0084ff;
  &::placeholder {
    color: #979eaa;
    font-style: italic;
  }
`;

export const TextInputArrow = styled(Arrow)`
  margin-top: 0.19em;
  margin-right: 0.4em;
`;
