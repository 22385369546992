import React from 'react';
import moment from 'moment';

import {
  Card,
  ContentWrapper,
  CardTitle,
  InfoWrapper,
  Column,
  Field,
  PDFButton,
  PrintButton,
} from './patient-card.style';
import { pdf } from '@react-pdf/renderer';
import TherapyPDF from '../../../services/pdf-react.service';
import LabelsPDF from '../../../services/pdflabels-react-service';

const PatientCard = ({
  loading,
  patient,
  therapy_code,
  therapyArray,
  dayName,
  therapyCalendar,
  createdAt,
  onExcelPrint,
  onPDFPrint,
  onStampaUnioneExcelPrint,
  onEtichetteExcelPrint,
}) => {
  if (loading) {
    return null;
  }

  const therapyPDF = (
    <TherapyPDF
      data={[therapy_code, therapyArray, patient, therapyCalendar, createdAt]}
    />
  );

  const labelsPDF = (
    <LabelsPDF data={[therapy_code, therapyArray, patient, createdAt]} />
  );

  const openTherapyPDF = async () => {
    const blob = await pdf(therapyPDF).toBlob();

    const pdfURL = URL.createObjectURL(blob);
    window.open(pdfURL, '_blank');
  };

  const openLabelsPDF = async () => {
    const blob = await pdf(labelsPDF).toBlob();

    const pdfURL = URL.createObjectURL(blob);
    window.open(pdfURL, '_blank');
  };

  return (
    <>
      <Card
        className='patientCard'
        style={{ alignSelf: 'start', minHeight: 630 }}
      >
        <ContentWrapper>
          <CardTitle>SCHEDA PAZIENTE</CardTitle>

          <InfoWrapper>
            <Column
              style={{
                display: 'flex',
                flexFlow: 'column wrap',
              }}
            >
              <Field name='Nome e Cognome' value={patient.name} />
              <Field
                name='Età'
                value={
                  moment(patient.birth_date, 'DD/MM/YYYY')
                    ? moment().diff(
                        moment(patient.birth_date, 'DD/MM/YYYY'),
                        'years'
                      )
                    : `Non disponibile.`
                }
              />
              <Field name='Patologia' value={patient.pathology} />
            </Column>
            <Column>
              <Field name='Struttura ospedaliera' value={patient.hospital} />
              <Field
                name='Medico curante / Medico reparto'
                value={patient.doctor}
              />
              <Field name='Farmacista preparatore' value={patient.pharmacist} />
            </Column>
            <Column>
              <PDFButton
                label='Stampa PDF Terapia'
                onClick={() => openTherapyPDF()}
              ></PDFButton>
              <PrintButton
                label='SCARICA PDF ETICHETTE'
                onClick={() => openLabelsPDF()}
              />

              <PrintButton
                label='SCARICA EXCEL TERAPIA'
                onClick={onExcelPrint}
              />
              <PrintButton
                label='STAMPA UNIONE DI EXCEL'
                onClick={onStampaUnioneExcelPrint}
              />
              <PrintButton
                label='SCARICA EXCEL ETICHETTE'
                onClick={onEtichetteExcelPrint}
              />
            </Column>
          </InfoWrapper>
        </ContentWrapper>
      </Card>
    </>
  );
};

export default PatientCard;
