import React from 'react';
import styled from 'styled-components';

import { ReactComponent as AddButtonCircle } from '../../../assets/add-button.svg';
import { ReactComponent as GlutenIcon } from '../../../assets/gluten-icon.svg';
import { ReactComponent as MedicineDetailsIcon } from '../../../assets/medicine-details-icon.svg';
/* import { ReactComponent as GlutenMsg } from '../../../assets/gluten-msg.svg'; */

export const MedicineRow = styled.div`
  padding: 1.5em 1.5em 1.5em 0.8em;
`;

export const Text = styled.p`
  margin: 0;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 3px solid #0084ff;
  overflow: hidden;
  box-sizing: border-box;
  & ${MedicineRow}:nth-child(odd) {
    background-color: #f7f9fa;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 3.1em;
`;

export const DayText = styled.h3`
  margin: 0;
  font-size: 1.9em;
  font-weight: normal;
  color: #0b3656;
  text-transform: uppercase;
`;

export const MedicineDate = styled.p`
  font-family: 'Lato';
  margin: 0;
  font-size: 1.1em;
  color: #0b3656;
`;

export const MedicineName = styled.div`
  margin: 0;
  font-size: 1.9em;
  color: #0084ff;
`;

export const MedicineTrackingCode = styled.p`
  margin: 0 0 8px 0;
  font-size: 1.2em;
  color: #0072dd;
`;

export const MedicineInfo = styled.p`
  font-family: 'Lato';
  display: inline;
  margin: 0;
  font-size: 1.1em;
  color: #0084ff;
`;

export const MedicineNote = styled.p`
  font-family: 'Lato';
  margin: 12px 0 0 0;
  font-size: 1.1em;
  color: #979eaa;
`;

export const EditButton = styled.input.attrs({
  type: 'button',
  value: 'Modifica',
})`
  font-family: 'Lato';
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 0.9em;
  color: #979eaa;
  cursor: pointer;
`;

export const AddButton = (p) => (
  <Button {...p}>
    <ButtonSvg />
  </Button>
);

const Button = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const ButtonSvg = styled(AddButtonCircle)`
  width: 2.4em;
  height: 2.4em;
`;

export const MedicineDetailsButton = styled(MedicineDetailsIcon)`
  cursor: pointer;
  height: 38px;
  width: 42px;
  padding: 0px 0px 10px 11px;
`;

export const HasGlutenIcon = () => (
  <HasLactoseGlutenWrapper title='Presenza di glutine'>
    <GlutenIconWrapper>
      <AppGlutenIcon />
    </GlutenIconWrapper>
  </HasLactoseGlutenWrapper>
);

const AppGlutenIcon = styled(GlutenIcon)`
  height: 1.7em;
  width: 0.8em;
`;

/* <TitleMessageWrapper>
      <InnerWrapper>
        <GlutenMsg />
      </InnerWrapper>
    </TitleMessageWrapper> */

const TitleMessageWrapper = styled.div`
  display: none;
  position: absolute;
  left: -54px;
  top: 10px;
  z-index: 200;
`;

/* const InnerWrapper = styled.div`
  position: absolute;
`; */

const GlutenIconWrapper = styled.div`
  position: absolute;
  top: -0.3em;
  left: 0.3em;
  display: inline;
`;

const HasLactoseGlutenWrapper = styled.div`
  position: relative;
  display: inline;
  &:hover ${TitleMessageWrapper} {
    display: initial;
  }
`;
