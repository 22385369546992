import React from "react";
import styled from "styled-components";

import FilledButton from "../../../components/buttons/filled-button";
import UnfilledButton from "../../../components/buttons/unfilled-button";

export const Card = styled.div`
  display: flex;
  grid-area: card;
  padding: 1.5vw 1.65vw;
  background-color: #0084ff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 1.3em;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    font-size: 1.2em;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 2em 2em;
  border: 3px solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
`;

export const CardTitle = styled.p`
  margin: 0;
  font-size: 1.8em;
  font-weight: 500;
  color: #ffffff;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2.4em;
`;

export const Column = styled.div`
  flex: 1 0 33%;
  box-sizing: border-box;
`;

export const Field = ({ name, value }) => (
  <FieldWrapper>
    <FieldName>{name}</FieldName>
    <FieldValue>{value}</FieldValue>
  </FieldWrapper>
);

const FieldWrapper = styled.div`
  margin-bottom: 1.5em;
`;

const FieldName = styled.p`
  font-family: "Lato";
  margin: 0;
  line-height: 20px;
  font-size: 18px;
  color: #ffffff;
`;

const FieldValue = styled.p`
  font-family: "Fira Sans Extra Condensed";
  margin: 0;
  font-size: 25px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
`;

export const PDFButton = styled(UnfilledButton).attrs((p) => ({
  value: "STAMPA PDF TERAPIA",
  fg: "#FFFFFF",
  bgh: "#0077E6",
}))`
  display: block;
  margin-bottom: 1.4em;
  height: 2.5em;
  border-radius: 10px;
  font-size: 1.2em;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    background-color: ${(p) => p.bgh};
  }
`;

export const PrintButton = styled(FilledButton).attrs((p) => ({
  value: p.label,
  fg: "#0084FF",
  bg: "#FFFFFF",
  bgh: "#EEEEEE",
}))`
  display: block;
  margin-bottom: 0.6em;
  padding: 0.4em 0.8em;
  border-radius: 10px;
  font-size: 1.15em;
  &:hover {
    background-color: ${(p) => p.bgh};
  }
`;
