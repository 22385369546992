import React, { useEffect, useState } from "react";
import {
  PatientWrapper,
  PatientHeader,
  PatientBody,
  PatientBodyTitle,
  PatientTherapy,
  EmptyListText
} from "./patient.style.js";
import Api from "./../../services/api.service.js";
import { useParams } from "react-router-dom";
import AppScrollable from './../../components/app-scrollable/app-scrollable.js';
import Loader from "../../components/loader/loader.js";

function Patient() {
  const [choosing, setChoosing] = useState(false);
  const [notfound, setNotfound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [choice, setChoice] = useState("recent");
  const [data, setData] = useState({});
  const [deleted, setDeleted] = useState(false);
  const { id } = useParams();

  const getPatient = async () => {
    try {
      const { data: response } = await Api.getPatient(id);
      setData(response);
      setLoading(false);
    } catch (e) {
      setNotfound(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatient();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [deleted]);

  let recentTherapies = [];
  let ancientTherapies = [];

  if (data.therapies !== null && data.therapies !== undefined && data.therapies.length > 0) {
    recentTherapies = Object.assign([], data.therapies).reverse();
    ancientTherapies =  data.therapies;
  }

  return (
    <PatientWrapper>
      {(loading === false && !notfound) &&
        <>
          <PatientHeader {...{ data }} />
          <PatientBody>
            <PatientBodyTitle {...{ choosing, setChoosing, choice, setChoice, id }} />
            <AppScrollable style={{ width: '100%', height: 400 }}>
              {choice === "recent"
                ? recentTherapies.map((item, index) => (
                  <PatientTherapy
                    patientId={id}
                    key={index}
                    data={item}
                    therapyId={item.id}
                    even={index % 2 === 0}
                    deleteTherapy={()=>{
                      Api.deleteTherapy(id, item.id);
                      setDeleted(!deleted);
                    }}
                  />
                ))
                : ancientTherapies.map((item, index) => (
                  <PatientTherapy
                    patientId={id}
                    key={index}
                    data={item}
                    therapyId={item.id}
                    even={index % 2 === 0}
                    deleteTherapy={()=>Api.deleteTherapy(id, item.id)}
                  />
                ))}
              {recentTherapies.length === 0 && <EmptyListText>Nessuna terapia per questo paziente</EmptyListText>}
            </AppScrollable>
          </PatientBody>
        </>}
      {notfound && <EmptyListText>Paziente non trovato.</EmptyListText>}
      {loading && <Loader/>}
    </PatientWrapper>
  );
}

export default Patient;
