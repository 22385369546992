import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import TherapyDayDetails from './therapy-details/therapy-details';
import PatientCard from './patient-card/patient-card';
import MedicineForm from './medicine-form/medicine-form';
import DatePicker from 'react-datepicker';

import styled from 'styled-components';
import FilledButton from '../../components/buttons/filled-button';
import { CancelButton } from '../therapy/medicine-form/medicine-form.style';
import { CloseButton } from '../../screens/therapy/medicine-form/medicine-form.style.js';

import {
  PageWrapper,
  GoBack,
  ContentWrapper,
  Overlay,
  FormWrapper,
} from './therapy.style';
import Api from '../../services/api.service';
import {
  createTherapiesArrayFromObject,
  createTherapiesObjectFromArray,
  modalStyles,
} from '../../services/utils.service';
import PDFRender from '../../services/pdf-render.service';
import Excel from '../../services/excel.service';

import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import Modal from 'react-modal';

function editTherapy(vect, item, index) {
  if (index === undefined) {
    vect.push(item);
  } else {
    vect[index] = item;
  }
}

function initTextFields(obj, keys) {
  keys.forEach((key) => {
    if (typeof obj[key] !== 'string') {
      obj[key] = '';
    }
  });
}

function TherapiesManagment() {
  const history = useHistory();
  const { p_id: patientId, t_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [therapyId, setTherapyId] = useState(null);
  const [form, setForm] = useState({ visibility: false });
  const [patient, setPatient] = useState(undefined);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState({
    createdAt: null,
    startDate: null,
    endDate: null,
    days: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  });

  useEffect(() => {
    Api.getPatient(patientId).then(({ data }) => setPatient(data));
    if (t_id !== undefined) {
      setTherapyId(t_id);
      Api.getTherapy(patientId, t_id)
        .then(
          ({
            data: { therapy_code, therapies, startDate, endDate, created_at },
          }) => {
            // convert date from string to js date
            therapies.forEach((therapy) => {
              therapy.forEach((m) => {
                if (m.startDate) m.startDate = new Date(m.startDate);
              });
            });

            setData({
              therapy_code,
              startDate,
              endDate,
              createdAt: moment(created_at),
              days: { ...createTherapiesObjectFromArray(therapies) },
            });
            setLoading(false);
          }
        )
        .catch((e) => {
          console.log(`Therapy screen getPatient:`, e);
          history.goBack();
        });
    } else {
      setLoading(false);
    }
  }, [patientId, t_id, history]);

  if (!patient) {
    return null;
  }

  const openForm = (day, data, editIndex = undefined, isEditing) => {
    window.scrollTo(0, 120);
    document.querySelector('body').style.overflow = 'hidden';

    data && initTextFields(data, ['name', 'time', 'note']);

    setForm({ day, visibility: true, data, editIndex, isEditing });
  };
  const closeForm = () => {
    document.querySelector('body').removeAttribute('style');
    setForm({ visibility: false });
  };

  async function onSubmit(fields) {
    // create a vector a days
    const therapies = createTherapiesArrayFromObject(data.days);
    switch (form.day) {
      case 'monday':
        editTherapy(therapies[0], fields, form.editIndex);
        break;
      case 'tuesday':
        editTherapy(therapies[1], fields, form.editIndex);
        break;
      case 'wednesday':
        editTherapy(therapies[2], fields, form.editIndex);
        break;
      case 'thursday':
        editTherapy(therapies[3], fields, form.editIndex);
        break;
      case 'friday':
        editTherapy(therapies[4], fields, form.editIndex);
        break;
      case 'saturday':
        editTherapy(therapies[5], fields, form.editIndex);
        break;
      case 'sunday':
        editTherapy(therapies[6], fields, form.editIndex);
        break;
      default:
        throw new Error('Unknown day in onSubmit(TherapyScreen)');
    }

    return Api.editTherapy(patientId, therapyId, therapies, {
      startDate: moment(data.startDate).format('DD/MM/YYYY').toString(),
      endDate: moment(data.endDate).format('DD/MM/YYYY').toString(),
    });
  }

  async function onDelete() {
    data.days[form.day].splice(form.editIndex, 1);
    const therapies = Object.keys(data.days).map((k) => data.days[k]);
    await Api.editTherapy(patientId, therapyId, therapies, {
      startDate: moment(data.startDate).format('DD/MM/YYYY').toString(),
      endDate: moment(data.endDate).format('DD/MM/YYYY').toString(),
    });
    closeForm();
  }

  function printPDF() {
    let contatoreEtichetta = 0;
    const pdfRender = new PDFRender('A4', false);
    const array = createTherapiesArrayFromObject(data.days);
    const dayName = [
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Giovedì',
      'Venerdì',
      'Sabato',
      'Domenica',
    ];

    array.forEach((day, i) => {
      // deltatime map avrà come key la fascia oraria e come valore tutti i medicinale associati
      const deltatimeMap = {};
      day.forEach((m) => {
        if (!deltatimeMap[m.deltatime]) deltatimeMap[m.deltatime] = [];
        deltatimeMap[m.deltatime].push(m);
      });

      Object.values(deltatimeMap).forEach((medicines) => {
        pdfRender.etichetta(
          contatoreEtichetta++,
          dayName[i],
          patient,
          medicines,
          data.createdAt
        );
        if (contatoreEtichetta === 12) {
          pdfRender.doc.addPage('A4');
          contatoreEtichetta = 0;
        }
      });
    });
    pdfRender.doc.save(`${patient.name} - Etichette Terapia Facile.pdf`);
  }

  const therapyArray = createTherapiesArrayFromObject(data.days);

  dayjs.locale('it');
  dayjs.extend(isoWeek);
  dayjs.extend(Duration);

  const amountOfTherapyDays = dayjs
    .duration(dayjs(data.endDate).diff(dayjs(data.startDate)))
    .asDays();

  let therapyCalendar = [];

  for (let i = 0; i < amountOfTherapyDays; i++) {
    therapyCalendar.push(moment(data.startDate).add(i, 'days'));
  }

  const inputStyleForm = (value) => {
    return {
      width: '100%',
      fontFamily: 'Lato',
      fontSize: 15,
      lineHeight: '30px',
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      outline: 'none',
      borderBottomColor: value === '' ? 'orange' : '#C9D3E2',
      marginBottom: 20,
    };
  };

  const CreateTherapyButton = styled(FilledButton).attrs({
    type: 'submit',
    value: 'CREA TERAPIA',
  })`
    width: 10.3em;
    height: 3.25em;
    border-radius: 10px;
    font-size: 1.1em;
    &:hover {
      border-color: #0079ea;
      background-color: #0079ea;
    }
  `;

  if (loading) {
    return (
      <PageWrapper>
        <GoBack style={{ cursor: 'progress' }}>Caricamento in corso...</GoBack>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Modal ariaHideApp={false} isOpen={!t_id} style={modalStyles}>
        <CloseButton
          onClose={() => {
            history.goBack();
          }}
        ></CloseButton>
        <div style={{ margin: '30px 30px 0px 30px', width: 375, height: 500 }}>
          <p style={{ marginBottom: 50, fontWeight: 600 }}>
            Inserisci la data di inizio della terapia e la data di fine
          </p>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              Api.editTherapy(
                patientId,
                undefined,
                [[], [], [], [], [], [], []],
                {
                  startDate: moment(event.target.startDate.value, 'DD/MM/YYYY')
                    .format('DD/MM/YYYY')
                    .toString(),
                  endDate: moment(event.target.endDate.value, 'DD/MM/YYYY')
                    .format('DD/MM/YYYY')
                    .toString(),
                }
              )
                .then(({ data }) =>
                  history.replace(`/patients/${patientId}/therapies/${data.id}`)
                )
                .catch(() => history.goBack());
            }}
          >
            <label htmlFor='startDate'>
              <span>Data di inizio</span>
            </label>
            <DatePicker
              required
              className='datePicker'
              name='startDate'
              dateFormat='dd/MM/yyyy'
              autoComplete='off'
              customInput={
                <input style={inputStyleForm(dayjs().toString())} type='text' />
              }
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
            />

            <label htmlFor='endDate'>
              <span>Data di fine</span>
            </label>
            <DatePicker
              required
              name='endDate'
              autoComplete='off'
              className='datePicker'
              dateFormat='dd/MM/yyyy'
              customInput={
                <input style={inputStyleForm(endDate)} type='text' />
              }
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
            />
            <div
              style={{
                marginTop: '200px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <CancelButton
                onClick={() => {
                  history.goBack();
                }}
              />
              <CreateTherapyButton />
            </div>
          </form>
        </div>
      </Modal>

      {t_id && (
        <div style={form.visibility ? { filter: 'blur(30px)' } : {}}>
          <p style={{ fontSize: 30, margin: '0px 0px 0px 10vw' }}>
            TERAPIA {therapyId} - {data.therapy_code}
          </p>
          <p style={{ fontSize: 15, margin: '0px 0px 10px 10vw' }}>
            Terapia dal {moment(data.startDate).format('DD/MM/YYYY').toString()}{' '}
            al {moment(data.endDate).format('DD/MM/YYYY').toString()}
          </p>
          <GoBack onClick={() => history.goBack()}>
            Torna alla scheda paziente
          </GoBack>

          <ContentWrapper>
            <TherapyDayDetails
              day='monday'
              therapy_code={data.therapy_code}
              openForm={openForm}
              medicines={data.days.monday}
            />
            <TherapyDayDetails
              day='tuesday'
              therapy_code={data.therapy_code}
              openForm={openForm}
              medicines={data.days.tuesday}
            />
            <TherapyDayDetails
              day='wednesday'
              therapy_code={data.therapy_code}
              openForm={openForm}
              medicines={data.days.wednesday}
            />
            <TherapyDayDetails
              day='thursday'
              therapy_code={data.therapy_code}
              openForm={openForm}
              medicines={data.days.thursday}
            />
            <TherapyDayDetails
              day='friday'
              openForm={openForm}
              medicines={data.days.friday}
            />
            <TherapyDayDetails
              day='saturday'
              therapy_code={data.therapy_code}
              openForm={openForm}
              medicines={data.days.saturday}
            />
            <TherapyDayDetails
              day='sunday'
              therapy_code={data.therapy_code}
              openForm={openForm}
              medicines={data.days.sunday}
            />

            <PatientCard
              therapy_code={data.therapy_code}
              createdAt={data.createdAt}
              therapyArray={therapyArray}
              therapyCalendar={therapyCalendar}
              patient={patient}
              loading={loading}
              onExcelPrint={() =>
                Excel.generateTherapy(
                  patient,
                  data,
                  `TF - ${patient.name} - ${data.createdAt.format(
                    'DD-MM-YYYY'
                  )}.xlsx`
                )
              }
              onEtichetteExcelPrint={() =>
                Excel.generateEtichette(patient, data)
              }
              onStampaUnioneExcelPrint={() =>
                Excel.generateStampaUnione(patient, data)
              }
              onPDFPrint={printPDF}
            />
          </ContentWrapper>
        </div>
      )}
      {form.visibility && (
        <Overlay>
          <FormWrapper>
            <MedicineForm
              {...{
                closeForm,
                onSubmit,
                initialValue: form.data,
                onDelete,
                isEditing: form.isEditing,
              }}
            />
          </FormWrapper>
        </Overlay>
      )}
    </PageWrapper>
  );
}

export default TherapiesManagment;
