import React from 'react';

import { OuterWrapper, InnerWrapper } from './dropdown-menu.style';


const DropDownMenu = ({ children, isVisible, className, style }) => (
  isVisible && <OuterWrapper><InnerWrapper {...{ className, style }}>{children}</InnerWrapper></OuterWrapper>
);

export default DropDownMenu;
