import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Circle,
} from "@react-pdf/renderer";
import isoWeek from "dayjs/plugin/isoWeek";
import moment from "moment";
import dayjs from "dayjs";
import { useZustandStore } from "./zustand-store";

dayjs.locale("it");
dayjs.extend(isoWeek);

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    marginVertical: 10,
  },
  headerTitles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
    fontSize: "9px",
    fontFamily: "Helvetica-Bold",
  },
  section: {
    marginTop: 5,
    marginHorizontal: 30,
    paddingTop: 5,
    flexGrow: 1,
  },
  labelFlexWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  labelContainer: {
    flex: "0 1 49%",
    display: "flex",
    flexDirection: "row",
    border: "0.5 solid grey",
    borderRadius: "3px",
    margin: "2px",
    padding: "5px",
  },
  labelColumn: {
    display: "flex",
    flexDirection: "column",
    width: "32%",
    height: "auto",
  },
  infoTextPair: { display: "flex", marginRight: 10 },
  text: {
    fontSize: 11,
    fontFamily: "Helvetica",
  },
  headerBig: {
    fontSize: 15,
  },
  header: {
    fontSize: 7,
    fontFamily: "Helvetica",
    fontWeight: "normal",
  },
  headerBold: {
    fontSize: 7,
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 6,
    top: 5,
    left: 518,
    right: 0,
    textAlign: "center",
    color: "black",
  },
});

// Create Document Component
const LabelsPDF = (data) => {
  // react-pdf/renderer seems to only accept props if inserted into an array for some reason, they turn into empty objects otherwise...
  const [therapy_code, therapyArray, patient, createdAt] =
    data.data;
    
  const { user } = useZustandStore();
  //   const daysMap = therapyArray.Map((day) => day);
  const weekdays = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica",
  ];
  
  const labels = therapyArray.map((day, dayIndex) =>
    day.map((pill) => (
      <View wrap={false} style={styles.labelContainer}>
        <View style={[styles.labelColumn, { justifyContent: "space-between" }]}>
          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Nome paziente</Text>
            <Text style={styles.header}>{patient.name}</Text>
          </View>
          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Età</Text>
            <Text style={[styles.header, { fontWeight: "bold" }]}>
              {moment().diff(moment(patient.birth_date, "DD/MM/YYYY"), "years")}
            </Text>
          </View>

          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Dottore curante</Text>
            <Text style={styles.header}>{user.name}</Text>
          </View>
          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Data Preparazione</Text>
            <Text style={styles.header}>{createdAt.format("DD/MM/YY")}</Text>
          </View>
        </View>

        <View style={[styles.labelColumn, { justifyContent: "space-between" }]}>
          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Giorno</Text>
            <Text style={styles.header}>{weekdays[dayIndex]}</Text>
          </View>
          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Farmaco</Text>
            <Text style={styles.header}>{pill.name}</Text>
          </View>
          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Quantità</Text>
            <Text style={styles.header}>{pill.quantity}</Text>
          </View>
        </View>

        <View style={styles.labelColumn}>
          <View style={styles.infoTextPair}>
            <Text style={styles.headerBold}>Somministrazione</Text>
            <Text style={styles.header}>
              {pill.method} alle {pill.time}
            </Text>
          </View>
          {pill.trackingCode && (
            <View style={styles.infoTextPair}>
              <Text style={styles.headerBold}>Nº identificativo</Text>
              <Text style={styles.header}>{pill.trackingCode}</Text>
            </View>
          )}
          {pill.note && (
            <View style={styles.infoTextPair}>
              <Text style={styles.headerBold}>Note aggiuntive</Text>
              <Text style={styles.header}>{pill.note}</Text>
            </View>
          )}
          {pill.expirationDate && (
            <View style={styles.infoTextPair}>
              <Text style={styles.headerBold}>Data di scadenza</Text>
              <Text style={styles.header}>{pill.expirationDate}</Text>
            </View>
          )}
        </View>
        <Svg
          viewBox="0 0 100 100"
          style={{
            width: 32,
            height: 32,
            position: "absolute",
            bottom: "0",
            right: "0",
          }}
        >
          <Circle cx="50%" cy="50%" r="15" fill={pill.color} />
        </Svg>
      </View>
    ))
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerTitles}>
            <Text>
              {user.pharmacy.name} - Dott. {user.name}
            </Text>
            <Text style={{ marginRight: "50px" }}>
              Etichette per Terapia Facile - Paziente {patient.name} (
              {therapy_code})
            </Text>
          </View>
          <View style={styles.labelFlexWrapper}>{labels}</View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};

export default LabelsPDF;
