import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import {
  DeleteButton,
  SaveButton,
  CancelButton,
  ConfirmDeleteButton,
  CancelDeleteButton,
} from "../therapy/medicine-form/medicine-form.style";

export const FlexRowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PatientWrapper = styled.div`
  padding: 80px 120px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 2fr));
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  margin-bottom: 40px;
`;

export const ModalElement = ({
  setData,
  data,
  closeModal,
  sendChanges,
  newPatient,
  openDeleteModal,
}) => (
  <ModalWrapper>
    <ModalTitle {...{ data, closeModal }} />
    <ModalBody>
      <ModalForm {...{ data, setData, newPatient }} />

      <FlexRowBox style={{ marginBottom: 30 }}>
        {newPatient ? (
          <CancelButton onClick={closeModal} />
        ) : (
          <DeleteButton onClick={openDeleteModal} />
        )}
        <SaveButton onClick={() => sendChanges(data)} />
      </FlexRowBox>
    </ModalBody>
  </ModalWrapper>
);

export const ModalWrapper = styled.div`
  border-radius: 5px;
  border: 3px solid #0084ff;
`;

export const ModalBody = styled.div`
  margin: 0 100px;
`;

export const ModalTitle = ({ data, closeModal }) => (
  <FlexRowBox
    style={{
      paddingLeft: 100,
      paddingRight: 25,
      marginBottom: 20,
      marginTop: 20,
      justifyContent: "space-between",
    }}
  >
    <Text style={{ fontSize: 30 }}>{data.name.toUpperCase()}</Text>
    <input
      style={{
        height: "36px",
        backgroundColor: "white",
        border: "none",
        color: "#979EAA",
        padding: 0,
        margin: 0,
        fontFamily: "Lato",
        fontSize: 25,
        outline: "none",
        cursor: "pointer",
      }}
      type="button"
      value="X"
      onClick={() => closeModal()}
    />
  </FlexRowBox>
);

const inputStyleForm = (value) => {
  return {
    width: "100%",
    fontFamily: "Lato",
    fontSize: 15,
    lineHeight: "30px",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    outline: "none",
    borderBottomColor: value === "" ? "orange" : "#C9D3E2",
    marginBottom: 20,
  };
};

export const ModalForm = ({ data, setData, newPatient }) => {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    let day =
      Number(startDate.getDate()) < 10
        ? "0" + startDate.getDate()
        : startDate.getDate();
    let month =
      Number(startDate.getMonth()) + 1 < 10
        ? "0" + Number(Number(startDate.getMonth()) + 1)
        : Number(startDate.getMonth()) + 1;
    let year = startDate.getFullYear();
    setData("birth_date", day + "/" + month + "/" + year);
  }, [startDate]);

  return (
    <ModalFormWrapper>
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Nome e Cognome
      </Text>
      <input
        value={data.name}
        onChange={(event) => setData("name", event.target.value)}
        style={inputStyleForm(data.name)}
        type="text"
      />
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Data di nascita
      </Text>
      <DatePicker
        className="datePicker"
        dateFormat="dd/MM/yyyy"
        customInput={<input style={inputStyleForm()} type="text" />}
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
        }}
      />
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Codice Fiscale
      </Text>
      <input
        value={data.cf}
        onChange={(event) => setData("cf", event.target.value)}
        style={inputStyleForm(data.cf)}
        type="text"
      />
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Patologia
      </Text>
      <input
        value={data.pathology}
        onChange={(event) => setData("pathology", event.target.value)}
        style={inputStyleForm(data.pathology)}
        type="text"
      />
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Struttura ospedaliera
      </Text>
      <input
        value={data.hospital}
        onChange={(event) => setData("hospital", event.target.value)}
        style={inputStyleForm(data.hospital)}
        type="text"
      />
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Reparto
      </Text>
      <input
        value={data.department}
        onChange={(event) => setData("department", event.target.value)}
        style={inputStyleForm(data.department)}
        type="text"
      />
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Medico curante / Medico reparto
      </Text>
      <input
        value={data.doctor}
        onChange={(event) => setData("doctor", event.target.value)}
        style={inputStyleForm(data.doctor)}
        type="text"
      />
      <Text style={{ fontFamily: "Lato", color: "#979EAA", fontSize: 15 }}>
        Farmacista preparatore
      </Text>
      <input
        value={data.pharmacist}
        onChange={(event) => setData("pharmacist", event.target.value)}
        style={inputStyleForm(data.pharmacist)}
        type="text"
      />
    </ModalFormWrapper>
  );
};

export const ModalFormWrapper = styled.div`
  margin-bottom: 20px;
  width: 375px;
`;

export const DeleteModal = ({ deletePatient, closeModal }) => (
  <div style={{ margin: 30 }}>
    <Text
      style={{
        fontStyle: "Fira Sans",
        fontSize: 20,
        fontWeight: "bold",
        color: "black",
        margin: 30,
        textAlign: "center",
      }}
    >
      Sei sicuro di voler eliminare il paziente?
    </Text>
    <FlexRowBox>
      <CancelDeleteButton
        style={{ marginRight: "10px" }}
        onClick={closeModal}
      />
      <ConfirmDeleteButton onClick={deletePatient} />
    </FlexRowBox>
  </div>
);
