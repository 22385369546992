import React from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/it";
import { Link } from "react-router-dom";

const width = window.innerWidth;

export const FlexRowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.p`
  margin: 0;
`;

export const PatientCardWrapper = styled.div`
  border: 3px solid #0084ff;
  border-radius: 5px;
  padding: 21px 34px;
  width: auto;
`;

export const PatientCardTile = ({ children, data, setShow, setPatient }) => (
  <FlexRowBox
    style={{
      justifyContent: "space-between",
      alignItems: "center",
      height: "72px",
    }}
  >
    <Link
      data={data}
      to={"/patients/" + data.id}
      style={{
        fontSize: width > 1280 ? 30 : 25,
        fontFamily: "Fira Sans Extra Condensed",
        fontWeight: 600,
        lineHeight: "36px",
        color: "#0B3656",
        textDecoration: "none",
        border: "none",
        background: "white",
        outline: "none",
        cursor: "pointer",
        textTransform: "uppercase",
      }}
    >
      {children}
    </Link>
    <input
      style={{
        backgroundColor: "white",
        border: "none",
        color: "#979EAA",
        fontStyle: "Lato",
        padding: 0,
        margin: 0,
        fontSize: width > 1280 ? 20 : 15,
        lineHeight: "24px",
        outline: "none",
        cursor: "pointer",
      }}
      type="button"
      value="Modifica"
      onClick={() => {
        setShow(true);
        setPatient(data);
      }}
    />
  </FlexRowBox>
);

export const PatientCardBody = ({ data }) => {
  const now = moment().format("L").split("/");
  const currentDate = {
    year: now[2],
    month: now[0],
    day: now[1],
  };
  const birthDateVect = data.birth_date.split("/");
  const years = parseInt(currentDate.year) - parseInt(birthDateVect[2]);
  const months = parseInt(currentDate.month) - parseInt(birthDateVect[1]);
  const days = parseInt(currentDate.day) - parseInt(birthDateVect[0]);
  let age;
  let label = "";

  if (years > 0) {
    age = years;
    label = years > 1 ? "anni" : "anno";
  } else if (months > 0) {
    age = months;
    label = months > 1 ? "mesi" : "mese";
  } else {
    age = days;
    label = days > 1 ? "giorni" : "giorno";
  }
  return (
    <PatientCardBodyWrapper>
      <PatientData label={"Età"} value={age + " " + label} />
      <PatientData label={"Patologia"} value={data.pathology} />
      <PatientData label={"Struttura ospedaliera"} value={data.hospital} />
      <PatientData label={"Reparto"} value={data.department} />
      <PatientData
        label={"Medico curante / Medico reparto"}
        value={data.doctor}
      />
      <PatientData label={"Farmacista preparatore"} value={data.pharmacist} />
    </PatientCardBodyWrapper>
  );
};

export const PatientData = ({ label, value }) => (
  <PatientDataWrapper label={label}>
    <Text
      style={{
        color: "#979EAA",
        fontSize: width > 1280 ? 20 : 15,
        fontFamily: "Lato",
      }}
    >
      {label}
    </Text>
    <Text
      style={{
        color: "#0084FF",
        fontSize: width > 1280 ? 20 : 15,
        fontFamily: "Lato",
      }}
    >
      {value}
    </Text>
  </PatientDataWrapper>
);

export const PatientDataWrapper = styled.div`
  margin-bottom: ${(p) =>
    p.label !== "Farmacista preparatore" ? "10" : "0"}px;
`;

export const PatientCardBodyWrapper = styled.div`
  margin-top: 10px;
`;
