import * as ExcelJS from "exceljs";
import moment from "moment";
import { saveAs } from "file-saver";

class Excel {
  static _therapyFormat = {
    colsAlignment: [
      "left",
      "right",
      "center",
      "left",
      "center",
      "center",
      "center",
      "center",
      "center",
      "center",
      "center",
    ],
    boldMap: {
      1: { 1: true },
      2: { 1: true },
      3: { 1: true },
      4: { 1: true },
      5: { 1: true },
      6: { 1: true },
      7: { 1: true },
      8: { 1: true },
      9: { 1: true },
      13: { 4: true },
      14: {
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
      },
    },
  };

  static generateTherapy = (patient, rawTherapy, filename = "terapia.xlsx") => {
    const therapy = this._convertTherapyNames(
      this._createTherapyArray(rawTherapy.days)
    );
    const colors = therapy.map((t) => t.colorText);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");

    worksheet.columns = [
      { header: "Scheda paziente", key: "scheda-paziente", width: 30 },
      { header: "", key: "patient-value", width: 25 },
      { header: "", key: "empty-col-1", width: 3 },
      { header: "", key: "date-col", width: 23 },
      { header: "", key: "time-col", width: 5 },
      {
        header: "",
        key: "deltatime-col",
        width: Math.max(18, ...therapy.map((m) => m.deltatime.length)),
      },
      {
        header: "",
        key: "name-col",
        width: Math.max(18, ...therapy.map((m) => m.name.length * 1.25)),
      },
      { header: "", key: "method-col", width: 31 },
      { header: "", key: "qauntity-col", width: 12 },
      {
        header: "",
        key: "color-col",
        width: Math.max(15, ...colors.map((c) => c.length)),
      },
      {
        header: "",
        key: "note-col",
        width: Math.max(
          20,
          ...therapy.map((m) => (m.note === null ? 20 : m.note.length + 10))
        ),
      },
    ];

    const patientRows = [
      ["Nome e cognome", patient.name],
      [
        "Età",
        "" + moment().diff(moment(patient.birth_date, "DD/MM/YYYY"), "years"),
      ],
      ["Medico curante", patient.doctor],
      ["Patologia", patient.pathology],
      ["Struttura ospedaliera", patient.hospital],
      ["Medico reparto", patient.doctor],
      ["Farmacista preparatore", patient.pharmacist],
      ["Data preparazione", rawTherapy.createdAt.format("DD/MM/YYYY")],
    ];

    const therapyRows = [
      ["Piano terapeutico"],
      [
        "GIORNO",
        "ORA",
        "FASCIA ORARIA",
        "NOME FARMACO",
        "MODALITA' SOMMINISTRAZIONE",
        "QUANTITA'",
        "COLORE",
        "RACCOMANDAZIONI",
      ],
      ...therapy.map((t, i) => [
        t.weekday,
        t.time.slice(0, t.time.indexOf(":")),
        t.deltatime,
        t.name,
        t.method,
        t.quantity,
        colors[i],
        t.note ? t.note : "nessuna",
      ]),
    ];

    worksheet.addRows([
      ...patientRows,
      [],
      [],
      [],
      ...therapyRows.map((t) => ["", "", "", ...t]),
    ]);

    const { colsAlignment, boldMap } = this._therapyFormat;
    worksheet.eachRow({ includeEmpty: true }, function (row, rowPos) {
      row.eachCell(function (cell, colPos) {
        const isBold = boldMap[rowPos] && boldMap[rowPos][colPos];

        cell.font = {
          name: "Arial",
          family: 2,
          bold: isBold,
          size: 10,
        };

        cell.alignment = {
          vertical: "middle",
          horizontal: colsAlignment[colPos - 1],
        };

        if (rowPos >= 15 && colPos >= 3) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: this.blobType });
      saveAs(blob, filename);
    });
  };

  static generateEtichette(patient, rawTherapy) {

    const therapy = this._convertTherapyNames(
      this._createTherapyArray(rawTherapy.days)
    );
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Etichette");

    const rows = [["Nome paziente", patient.name]];
    therapy.forEach((m) => {
      rows.push(["GIORNO", "NOME FARMACO"]);
      rows.push([m.weekday, m.name]);
      rows.push(["ORA", m.time]);
      rows.push(["Quantità", m.quantity]);
      rows.push(["SOMMINISTRAZIONE", m.method]);
      rows.push(["AVVISI", m.note || "nessuna"]);
      rows.push(["COLORE", m.colorText]);
      rows.push(["", ""]);
    });

    worksheet.addRows(rows);
    worksheet.eachRow((row, rowPos) => {
      row.eachCell((cell, colPos) => {
        const rowIndex = rowPos - 2; // faccio partire l'indice dopo la riga per il nome del paziente
        const etichettaOffset = rowIndex % 8;
        const isBold =
          (rowPos === 1 && colPos === 1) ||
          etichettaOffset === 0 ||
          (etichettaOffset >= 2 && etichettaOffset <= 6 && colPos === 1);

        cell.font = this._defaultFont(isBold);
        cell.alignment = this._defaultAlignment();
        if (etichettaOffset !== 7) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
    });

    this._setSheetAutoWidth(worksheet);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: this.blobType });
      saveAs(blob, `TF - ${patient.name} - Etichette EXC.xlsx`);
    });
  }

  static generateStampaUnione(patient, rawTherapy) {
    const therapy = this._convertTherapyNames(
      this._createTherapyArray(rawTherapy.days)
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("stampa unione");

    worksheet.addRows([
      [
        "Paziente",
        "Età",
        "Farmacista",
        "Data P",
        "GIORNO",
        "ORA",
        "FASCIA ORARIA",
        "NOME FARMACO",
        "SOMMINISTRAZIONE",
        "QT",
        "COLORE",
        "AVVISI",
      ],
      ...therapy.map((m) => [
        patient.name,
        moment()
          .diff(moment(patient.birth_date, "DD/MM/YYYY"), "years")
          .toFixed(),
        patient.pharmacist,
        rawTherapy.createdAt.format("DD/MM/YYYY"),
        m.weekday,
        m.time,
        m.deltatime,
        m.name,
        m.method,
        m.quantity.toString(),
        m.colorText,
        m.note || "nessuna",
      ]),
    ]);
    worksheet.getRow(1).eachCell((cell) => {
      cell.alignment = this._defaultAlignment("center");
    });
    worksheet.eachRow((row, rowPos) => {
      row.font = this._defaultFont(rowPos === 1);
    });

    this._setSheetAutoWidth(worksheet);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: this.blobType });
      saveAs(blob, `TF - ${patient.name} - Unione EXC.xlsx`);
    });
  }

  static _setSheetAutoWidth(worksheet) {
    const cols = worksheet.columns;

    for (let i = 0; i < cols.length; i++) {
      let colMaxLen = 0;
      const col = cols[i];
      for (let j = 1; j < col.values.length; j++) {
        const columnLength = this._weightedLength(col.values[j]);
        if (columnLength > colMaxLen) {
          colMaxLen = columnLength;
        }
      }
      col.width = colMaxLen + 1;
    }
  }

  /**
   * Create an array of medicine with name converted from html string to text
   * @param {any[]}
   */
  static _convertTherapyNames = (therapy) =>
    therapy.map((m) => {
      const div = document.createElement("div");
      div.innerHTML = m.name;
      return { ...m, name: div.textContent.trim() };
    });

  /**
   * Create an array from therapy object with the weekday associated
   * @param {Object}
   */
  static _createTherapyArray = (therapy) => [
    ...therapy.monday.map((m) => ({ ...m, weekday: "Lunedì" })),
    ...therapy.tuesday.map((m) => ({ ...m, weekday: "Martedì" })),
    ...therapy.wednesday.map((m) => ({ ...m, weekday: "Mercoledì" })),
    ...therapy.thursday.map((m) => ({ ...m, weekday: "Giovedì" })),
    ...therapy.friday.map((m) => ({ ...m, weekday: "Venerdì" })),
    ...therapy.saturday.map((m) => ({ ...m, weekday: "Sabato" })),
    ...therapy.sunday.map((m) => ({ ...m, weekday: "Domenica" })),
  ];

  static _defaultFont = (isBold = false) => ({
    name: "Arial",
    family: 2,
    bold: isBold,
    size: 10,
  });

  static _defaultAlignment = (horizontal = "left", vertical = "middle") => ({
    vertical,
    horizontal,
  });

  static _weightedLength(str, uppercaseWeight = 1.2) {
    const len = str.length;
    let res = 0;
    for (let i = 0; i < len; i++) {
      const ch = str[i];
      res += ch === ch.toUpperCase() ? uppercaseWeight : 1;
    }
    return res;
  }
}

export default Excel;
