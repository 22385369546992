import React, { useState, useEffect, useRef, useCallback } from 'react';
import Api from '../../../../services/api.service';
import { useDebounce } from 'use-debounce';

import TextInput from './text-input/text-input';
import AppListing from '../../../../components/app-listing/app-listing';
import DropDownMenu from '../../../../components/dropdown-menu/dropdown-menu.component';
import Modal from 'react-modal';
import { AutocompleteWrapper, BarCodeButton } from './search-bar.style';
import Loader from '../../../../components/loader/loader';
import { ReactComponent as BarCodeImage } from '../../../../assets/barcode-image.svg';
import { modalStyles } from '../../../../services/utils.service';
import { CloseButton } from '../medicine-form.style';

function getHtmlText(html) {
  const div = document.createElement('div');
  div.innerHTML = html.trim();
  return div.textContent;
}

function SearchBar({
  value,
  name,
  onChange,
  placeholder,
  setLactoseGluten,
  setMedicineId,
}) {
  const [checkingMedicine, setCheckingMedicine] = useState(false);
  const [text, setText] = useState(getHtmlText(value));
  const [list, setList] = useState([]);
  const [listVisibility, setListVisibility] = useState(false);

  const [barCode, setBarCode] = useState();
  const [barCodeModal, setBarCodeModal] = useState(false);

  const wrapperRef = useRef();
  const [search] = useDebounce(value, 200);

  const showList = useCallback(
    () => setListVisibility(true),
    [setListVisibility]
  );
  const hideList = useCallback(() => {
    setListVisibility(false);
    if (value === '') {
      setList([]);
    }
  }, [setListVisibility, value]);

  const onItemClick = useCallback(
    async (id) => {
      try {
        let add = true;
        setCheckingMedicine(true);
        const { data: item } = await Api.getMedicineById(id);
        if (
          item.glutine &&
          !window.confirm(
            'Questo farmaco contiene glutine. Sei sicuro di volerlo aggiungere?'
          )
        ) {
          add = false;
        } else if (
          item.lattosio &&
          !window.confirm(
            'Questo farmaco contiene glutine. Sei sicuro di volerlo aggiungere?'
          )
        ) {
          add = false;
        }
        if (add) {
          hideList();
          onChange({ target: { name: 'name', value: item.name } });
          setText(getHtmlText(item.name));
          setLactoseGluten(item.glutine || item.lattosio);
          setMedicineId(item.id);
        }
        setCheckingMedicine(false);
      } catch {
        setCheckingMedicine(false);
      }
    },
    [hideList]
  );

  const onTextChange = useCallback(
    ({ target: { value: newValue } }) => {
      setText(newValue);
      if (newValue === '') {
        onChange({ target: { name, value: '' } });
        setList([]);
      } else {
        Api.medicineSearch(newValue).then(({ data }) =>
          data instanceof Array ? setList(data) : setList([])
        );
      }
    },
    [setText, onChange, name]
  );

  const handleClick = useCallback(
    (evt) => {
      if (!wrapperRef.current) return;

      if (wrapperRef.current.contains(evt.target)) {
        showList();
      } else {
        hideList();
        // setText(getHtmlText(value));
      }
    },
    [showList, hideList]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick]);

  useEffect(() => {
    if (search !== '') {
      Api.medicineSearch(search).then(({ data }) =>
        data instanceof Array ? setList(data) : setList([])
      );
    }
  }, [search]);

  useEffect(() => {
    const updateCode = (event) => {
      if (event.keyCode === 13) {
        setBarCode((prev) => (prev.startsWith('A') ? prev.slice(1) : prev));
        return setBarCodeModal(false);
      }
      setBarCode((prev) => (!prev ? event.key : `${prev}${event.key}`));
    };

    if (barCodeModal) {
      document.addEventListener('keypress', updateCode);
    } else {
      document.removeEventListener('keypress', updateCode);
    }

    return () => document.removeEventListener('keypress', updateCode);
  }, [barCodeModal]);

  useEffect(() => {
    if (barCode && !barCodeModal) {
      onItemClick(barCode);
    }
  }, [barCode, barCodeModal, onItemClick]);

  return (
    <AutocompleteWrapper ref={wrapperRef}>
      <Modal ariaHideApp={false} isOpen={checkingMedicine} style={modalStyles}>
        <div style={{ margin: 30 }}>
          <p>Selezione farmaco in corso...</p>
          <Loader />
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={barCodeModal}
        style={modalStyles}
        shouldCloseOnOverlayClick
        onRequestClose={() => setBarCodeModal(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px 60px',
          }}
        >
          <CloseButton onClose={() => setBarCodeModal(false)} />
          <BarCodeImage />
          <div
            style={{
              fontFamily: 'Lato',
              fontWeight: 'bold',
              fontSize: '20px',
              lineHeight: '36px',
              color: '#0084FF',
              paddingTop: '70px',
              textAlign: 'center',
            }}
          >
            <p style={{ fontSize: '25px' }}>Codice A.I.C.</p>
            <p style={{ paddingTop: '5px' }}>In attesa di scansione...</p>
          </div>
        </div>
      </Modal>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '67px',
          marginLeft: '-25px',
        }}
      >
        <BarCodeButton
          onClick={() => {
            setBarCode();
            setBarCodeModal(true);
          }}
          style={{ height: '67px', width: '68px' }}
        />

        <TextInput
          value={text}
          onChange={onTextChange}
          onFocus={showList}
          placeholder={placeholder}
          style={{ width: '325px' }}
          autoComplete='new-password'
        />
      </div>

      <DropDownMenu isVisible={listVisibility}>
        <AppListing
          type='html'
          onItemClick={onItemClick}
          maxHeight={550}
          list={list}
        />
      </DropDownMenu>
    </AutocompleteWrapper>
  );
}

export default SearchBar;
