import styled from 'styled-components';

import { ReactComponent as CheckMarkSVG } from '../../assets/check-mark.svg';


export const Wrapper = styled.button.attrs({ type:'button' })`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: not-allowed;
`;

export const Square = styled.div`
  height: 1.3em;
  width: 1.3em;
  box-sizing: border-box;
  border: 2px solid #cccccc;
`;

export const CheckMark = styled(CheckMarkSVG)`
  position: absolute;
  width: 1.8em;
  height: 1.38em;
  margin-top: -0.38em;
  margin-left: -0.55em;
`;
