import React from 'react';
import styled from 'styled-components';
import Arrow from '../../../../components/arrow/arrow';


export const ListWrapper = styled.div``;

export const HorizontalLine = styled.div`
  height: 3px;
  background-color: #0084FF;
`;

export const SelectedValue = ({ active, value, placeholder, activeWidth, toggleVisibility, textStyle }) => (
  <SelectedValueWrapper onClick={toggleVisibility} style={active ? { minWidth: activeWidth } : {}}>
    <SelectedText style={value === undefined ? { ...textStyle, color: '#979EAA', fontStyle: 'italic' } : textStyle}>
      {value === undefined ? placeholder : value}
    </SelectedText>
    <PickerArrow active={active} />
  </SelectedValueWrapper>
);

const SelectedValueWrapper = styled.button.attrs(_ => ({
  type: 'button'
}))`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
`;

const SelectedText = styled.p`
  font-family: 'Lato';
  margin: 0;
  font-size: 1.2em;
  color: #0084FF;
`;

const PickerArrow = styled(Arrow)`
  margin-right: 7px;
  margin-left: 33px;
`;
