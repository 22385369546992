import React from 'react';
import styled from 'styled-components';


const Arrow = ({ active, className }) => (
  <ArrowSvg 
    className={className}
    style={active ? { transform: 'rotate(90deg)' } : {}}
    xmlns="http://www.w3.org/2000/svg" 
    width="12.475" height="20.707" 
    viewBox="0 0 12.475 20.707"
  >
    <path 
      d="M567.206,1446.7l9.293,9.293-9.293,9.293" 
      transform="translate(-566.146 -1445.635)" 
      fill="none" 
      stroke={active ? '#0084FF' : '#979eaa'} 
      strokeWidth="3" 
    />
  </ArrowSvg>
);

const ArrowSvg = styled.svg`
  width: 0.78em;
  height: 1.3em;
  transition: transform 0.25s;
`;

export default Arrow;
